import * as d3 from "d3";
import { setDefaultNumberFormat } from "../../../helpers/util";
import {
  getSynchronousZooming,
  getSnappingStatus,
} from "../../subjects/toolbar_subjects";

export default class Quadrant {
  static quadrants = [];

  constructor(
    id,
    formulaX,
    formulaY,
    formulaZ,
    isOnLeftSide,
    isOnBottomSide,
    index,
    viewBoxWidth,
    viewBoxHeight,
    currentProject,
    xMainInterval,
    yMainInterval,
    xNumberIntervals,
    yNumberIntervals,
    xNumberOfHelperIntervals,
    yNumberOfHelperIntervals,
    xNumberOfDecimalPoints,
    yNumberOfDecimalPoints,
    amountDataSeries,
    minDataSeries,
    stepsDataSeries,
    xMin,
    yMin,
    xMax,
    yMax,
    svgMargin,
    svgRef,
    areXAxisCongruent
  ) {
    this.id = id;
    this.formulaX = formulaX;
    this.formulaY = formulaY;
    this.formulaZ = formulaZ;
    this.index = index;
    this.number = index + 1;

    this.isLeft = isOnLeftSide;
    this.isRight = !isOnLeftSide;
    this.isBottom = isOnBottomSide;
    this.isTop = !isOnBottomSide;

    this.isFirstQuadrant = this.isRight && this.isTop;
    this.isSecondQuadrant = this.isLeft && this.isTop;
    this.isThirdQuadrant = this.isLeft && this.isBottom;
    this.isFourthQuadrant = this.isRight && this.isBottom;

    this.viewBoxWidth = viewBoxWidth;
    this.viewBoxHeight = viewBoxHeight;
    this.currentProject = currentProject;
    this.xMin = xMin;
    this.xMainInterval = xMainInterval;
    this.xNumberIntervals = xNumberIntervals;
    this.xNumberOfHelperIntervals = xNumberOfHelperIntervals;
    this.xNumberOfDecimalPoints = xNumberOfDecimalPoints;
    this.yMin = yMin;
    this.yMainInterval = yMainInterval;
    this.yNumberIntervals = yNumberIntervals;
    this.yNumberOfHelperIntervals = yNumberOfHelperIntervals;
    this.yNumberOfDecimalPoints = yNumberOfDecimalPoints;
    this.amountDataSeries = amountDataSeries;
    this.minDataSeries = minDataSeries;
    this.stepsDataSeries = stepsDataSeries;
    this.xMax = xMax;
    this.yMax = yMax;
    this.width = viewBoxWidth - svgMargin.left - svgMargin.right;
    this.height = viewBoxHeight - svgMargin.top - svgMargin.bottom;
    this.containerWidth = viewBoxWidth;
    this.containerHeight = viewBoxHeight;
    this.svgMargin = svgMargin;
    this.svgRef = svgRef;
    this.workingLines = [];
    this.horizontalNeighbor = null;
    this.verticalNeighbor = null;
    this.diagonalNeighbor = null;
    this.axisX = null;
    this.axisY = null;
    this.zoomLevel = 1;
    this.label = "";
    this.isSyncZooming = false;
    this.isLineSnappingEnabled = false;
    this.areXAxisCongruent = areXAxisCongruent;
    this.xGrid = null;
    this.zoomTransform = null;

    this.formulars = {
      min: null,
      numberOfLines: [],
      stepRate: null,
      formulaY: null,
      newX: null,
      newY: null,
    };
  }

  init() {
    this.defineChartDirection();
    this.appendSVG();
    this.addAxis();
    this.addBackgroundLabelText();
    this.initSynchronousZoomingListener();
    this.initIsLineSnappingEnabledListener();
  }

  addBackgroundLabelText() {
    if (this.number === 1) this.label = "I";
    else if (this.number === 2) this.label = "II";
    else if (this.number === 3) this.label = "III";
    else if (this.number === 4) this.label = "IV";
  }

  generateRange = (min, max, step = 1) =>
    Array.from(
      { length: Math.floor((max - min) / step) + 1 },
      (_, i) => min + i * step
    );

  defineChartDirection() {
    if (this.isLeft) {
      this.x = d3
        .scaleLinear([this.xMin, this.xMax])
        .domain([this.xMin, this.xMax])
        .range([this.width, 0]);
    } else {
      this.x = d3
        .scaleLinear()
        .domain([this.xMin, this.xMax])
        .range([0, this.width]);
    }
    if (this.isBottom) {
      this.y = d3
        .scaleLinear()
        .domain([this.yMin, this.yMax])
        .range([0, this.height]);
    } else {
      this.y = d3
        .scaleLinear()
        .domain([this.yMin, this.yMax])
        .range([this.height, 0]);
    }
  }

  appendSVG() {
    this.svg = d3
      .select(this.svgRef.current)
      .attr("width", this.width + this.svgMargin.left + this.svgMargin.right)
      .attr("height", this.height + this.svgMargin.top + this.svgMargin.bottom)
      .append("g")
      .attr(
        "transform",
        `translate(${this.svgMargin.left},${this.svgMargin.top})`
      );
  }

  addAxis() {
    this.defineAxis();
    this.defineHelperAxis();
    const color = "black";
    const stroke = 1;
    const fillOpacity = "1";

    if (this.isLeft && this.isTop) {
      this.axisX = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--x xAxisLeftTop font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .attr("transform", `translate(0,${this.height})`)
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisBottom);

      this.svg
        .append("g")
        .attr("transform", `translate(0,${this.height})`)
        .call(this.helperAxisBottom);

      this.axisY = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--y yAxisLeftTop font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .attr("transform", `translate( ${this.width}, 0 )`)
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisRight);

      this.svg
        .append("g")
        .attr("transform", `translate( ${this.width}, 0 )`)
        .call(this.helperAxisRight);
    } else if (this.isRight && this.isTop) {
      this.axisX = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--x xAxisRightTop font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .attr("transform", `translate(0,${this.height})`)
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisBottom);

      this.svg
        .append("g")
        .attr("transform", `translate(0,${this.height})`)
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .call(this.helperAxisBottom);

      this.axisY = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--y yAxisRightTop font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisLeft);

      this.svg.append("g").call(this.helperAxisLeft);
    } else if (this.isLeft && this.isBottom) {
      this.axisX = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--x xAxisLeftBottom font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisTop);

      this.svg.append("g").call(this.helperAxisTop);

      this.axisY = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--y yAxisLeftBottom font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .attr("transform", `translate( ${this.width}, 0 )`)
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisRight);

      this.svg
        .append("g")
        .attr("transform", `translate( ${this.width}, 0 )`)
        .call(this.helperAxisRight);
    } else if (this.isRight && this.isBottom) {
      this.axisX = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--x xAxisRightBottom font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisTop);

      this.svg.append("g").call(this.helperAxisTop);

      this.axisY = this.svg
        .append("g")
        .attr(
          "class",
          `axis axis--y yAxisRightBottom font${
            this.currentProject.detailsInformation.fontSize || 12
          }`
        )
        .style(
          "font-size",
          this.currentProject.detailsInformation.fontSize || 12
        )
        .style("fill-opacity", fillOpacity)
        .style("stroke", stroke)
        .style("color", color)
        .call(this.axisLeft);

      this.svg.append("g").call(this.helperAxisLeft);
    }
  }

  defineAxis() {
    const xFormatString =
      this.xNumberOfDecimalPoints > 0
        ? `,.${this.xNumberOfDecimalPoints}f`
        : ",.0f";
    const yFormatString =
      this.yNumberOfDecimalPoints > 0
        ? `,.${this.yNumberOfDecimalPoints}f`
        : ",.0f";

    setDefaultNumberFormat();

    const entryListXValues = this.generateRange(
      this.xMin,
      this.xMax,
      this.xMainInterval
    );

    const entryListYValues = this.generateRange(
      this.yMin,
      this.yMax,
      this.yMainInterval
    );

    this.axisBottom = d3
      .axisBottom(this.x)
      .tickValues(entryListXValues)
      .tickFormat(d3.format(xFormatString));
    this.axisTop = d3
      .axisTop(this.x)
      .tickValues(entryListXValues)
      .tickFormat(d3.format(xFormatString));
    this.axisLeft = d3
      .axisLeft(this.y)
      .tickValues(entryListYValues)
      .tickFormat(d3.format(yFormatString));
    this.axisRight = d3
      .axisRight(this.y)
      .tickValues(entryListYValues)
      .tickFormat(d3.format(yFormatString));
  }

  defineHelperAxis() {
    const ticksX = Quadrant.createAndFillArray(
      this.xNumberOfHelperIntervals,
      this.xNumberIntervals
    );
    const ticksY = Quadrant.createAndFillArray(
      this.yNumberOfHelperIntervals,
      this.yNumberIntervals
    );

    const xScale = d3
      .scaleLinear()
      .domain([ticksX[0], ticksX[ticksX.length - 1]])
      .range([0, this.width]);

    const yScale = d3
      .scaleLinear()
      .domain([ticksY[0], ticksY[ticksY.length - 1]])
      .range([0, this.height]);

    this.helperAxisBottom = d3
      .axisBottom(xScale)
      .tickSize(-5)
      .tickPadding(5)
      .tickValues(ticksX)
      .tickFormat("");

    this.helperAxisTop = d3
      .axisTop(xScale)
      .tickSize(-5)
      .tickPadding(5)
      .tickValues(ticksX)
      .tickFormat("");

    this.helperAxisLeft = d3
      .axisLeft(yScale)
      .tickSize(-5)
      .tickPadding(5)
      .tickValues(ticksY)
      .tickFormat("");

    this.helperAxisRight = d3
      .axisRight(yScale)
      .tickSize(-5)
      .tickPadding(5)
      .tickValues(ticksY)
      .tickFormat("");
  }

  removeWorkingLine(workingLine) {
    const index = this.workingLines.indexOf(workingLine);
    if (index > -1) {
      this.workingLines.splice(index, 1);
    }
  }

  updateAxisRange(x, y) {
    if (this.isSecondQuadrant) {
      this.xMin = x.invert(this.width);
      this.xMax = x.invert(0);
      this.yMin = y.invert(this.height);
      this.yMax = y.invert(0);
    } else if (this.isFirstQuadrant) {
      this.xMin = x.invert(0);
      this.xMax = x.invert(this.width);
      this.yMin = y.invert(this.height);
      this.yMax = y.invert(0);
    } else if (this.isThirdQuadrant) {
      this.xMin = x.invert(this.width);
      this.xMax = x.invert(0);
      this.yMin = y.invert(0);
      this.yMax = y.invert(this.height);
    } else {
      this.xMin = x.invert(0);
      this.xMax = x.invert(this.width);
      this.yMin = y.invert(0);
      this.yMax = y.invert(this.height);
    }
  }

  initSynchronousZoomingListener() {
    this.eventSynchZooming = getSynchronousZooming().subscribe((value) => {
      this.isSyncZooming = value;
    });
  }

  initIsLineSnappingEnabledListener() {
    this.eventLineSnappingEnabled = getSnappingStatus().subscribe((value) => {
      this.isLineSnappingEnabled = value;
    });
  }

  unsubscribeSynchZooming() {
    this.eventSynchZooming.unsubscribe();
  }

  unsubscribeIsLineSnappingEnabled() {
    this.eventLineSnappingEnabled.unsubscribe();
  }

  static createAndFillArray(numberOfHelperIntervals, numberIntervals) {
    const totalNumberStrokes = numberOfHelperIntervals * numberIntervals;
    const ticks = [];
    for (let i = 0; i <= totalNumberStrokes; i += 1) {
      ticks.push({ value: i });
    }
    return ticks.map((t) => t.value);
  }

  static connectNeighbors() {
    if (Quadrant.quadrants.length < 4) return;
    Quadrant.firstQuadrant = Quadrant.quadrants[0];
    Quadrant.secondQuadrant = Quadrant.quadrants[1];
    Quadrant.thirdQuadrant = Quadrant.quadrants[2];
    Quadrant.fourthQuadrant = Quadrant.quadrants[3];

    Quadrant.firstQuadrant.horizontalNeighbor = Quadrant.secondQuadrant;
    Quadrant.firstQuadrant.verticalNeighbor = Quadrant.fourthQuadrant;
    Quadrant.firstQuadrant.diagonalNeighbor = Quadrant.thirdQuadrant;
    Quadrant.secondQuadrant.horizontalNeighbor = Quadrant.firstQuadrant;
    Quadrant.secondQuadrant.verticalNeighbor = Quadrant.thirdQuadrant;
    Quadrant.secondQuadrant.diagonalNeighbor = Quadrant.fourthQuadrant;
    Quadrant.thirdQuadrant.horizontalNeighbor = Quadrant.fourthQuadrant;
    Quadrant.thirdQuadrant.verticalNeighbor = Quadrant.secondQuadrant;
    Quadrant.thirdQuadrant.diagonalNeighbor = Quadrant.firstQuadrant;
    Quadrant.fourthQuadrant.horizontalNeighbor = Quadrant.thirdQuadrant;
    Quadrant.fourthQuadrant.verticalNeighbor = Quadrant.firstQuadrant;
    Quadrant.fourthQuadrant.diagonalNeighbor = Quadrant.secondQuadrant;
  }

  static addQuadrants(quadrants) {
    Quadrant.quadrants = quadrants.sort((a, b) => a.index - b.index);
  }
}

import * as d3 from "d3";
import { v4 as uuidv4 } from "uuid";
import numeral from "numeral";
import { useLocation } from "react-router-dom";
import {
  onLocalStorageReadChange,
  onLocalStorageWriteChange,
} from "../components/subjects/localStorageSubjects";
import "numeral/locales/de";
import "numeral/locales/en-gb";
import localeDE from "../lang/de-DE.json";
import localeEN from "../lang/en-US.json";

numeral.localeData("de").delimiters.thousands = ".";

export function roundDecimalPlaces(numberString, digits) {
  const factor = 10 ** digits;
  return (
    +Math.round((parseFloat(numberString) + Number.EPSILON) * factor) / factor
  );
}

export function rangeFunction(xMin, xMax, fractionDigits, steps) {
  const numberOfSteps = steps || 100;
  const stepSize = xMax / numberOfSteps;

  if (xMin >= xMax) return [];

  const result = [];
  for (let decimal = xMin; decimal <= xMax; decimal += stepSize) {
    result.push(+roundDecimalPlaces(decimal, fractionDigits));
  }

  // Set to remove dublicates
  return [...new Set(result)];
}

export function simpleRangeFunction(xMin, xMax, interval) {
  const length = (xMax - xMin) / interval + 1;
  return Array.from({ length }, (_, i) => xMin + i * interval);
}

export function lineFunction(zValue, x, y, formula) {
  return d3
    .line()
    .x((d) => x(+d))
    .y((d) => y(formula(Number(d), Number(zValue))));
}

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isNumeric(num) {
  return !Number.isNaN(num);
}

export function isPositiveNumber(num) {
  return isNumeric(num) && num >= 0;
}

export function storeObjectInLocalStorage(keyName, obj) {
  if (obj !== undefined && obj) {
    localStorage.removeItem(keyName);
    localStorage.setItem(keyName, JSON.stringify(obj));
    onLocalStorageWriteChange();
  }
}

export function readObjectFromLocalStorage(keyName) {
  if (localStorage.getItem(keyName) === null) return null;
  onLocalStorageReadChange();
  return JSON.parse(localStorage.getItem(keyName));
}

export function extractDrawingInfos(lines) {
  const workingLines = [];
  lines.forEach((line) => {
    const obj = {};
    obj.valueXAxis = line.valueXAxis;
    obj.valueYAxis = line.valueYAxis;
    obj.labelX = line.labelX;
    obj.labelY = line.labelY;
    obj.valueZ = line.valueZ;
    obj.color = line.color;
    obj.name = line.name;
    obj.strokeWidth = line.strokeWidth;
    obj.strokeIndex = line.strokeIndex;
    obj.isCombined = line.isCombined;
    obj.arrowDirectionIndex = line.arrowDirectionIndex;
    obj.sequenceNumber = line.sequenceNumber;
    obj.decimalPlaces = line.decimalPlaces;
    obj.isVisible = line.isVisible;
    obj.stickTheLabel = line.stickTheLabel;
    if (line.putInLocalStorage) workingLines.push(obj);
  });
  return workingLines;
}

export function readWorkingLinesFromLocalStorage(quadrant) {
  const projectId = parseInt(localStorage.getItem("projectId"), 10);
  const userProjects = readObjectFromLocalStorage("userProjects");
  if (userProjects) {
    const { workingLines } = userProjects[projectId].quadrants[quadrant.index];
    if (workingLines !== undefined) return workingLines;
  }
  return [];
}

export function storeWorkingLinesInLocalStorage(quadrant) {
  const storableAttributes = extractDrawingInfos(quadrant.workingLines);
  const projectId = parseInt(localStorage.getItem("projectId"), 10);
  const userProjects = readObjectFromLocalStorage("userProjects");
  if (userProjects) {
    userProjects[projectId].quadrants[quadrant.index].workingLines =
      Array.from(storableAttributes);
    storeObjectInLocalStorage("userProjects", userProjects);
  }
}

export function doesArrayExistsOrIsEmpty(array) {
  return Array.isArray(array) && array.length;
}

// Deep compare
export function areObjectsEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function deepClone(obj) {
  if (obj) return JSON.parse(JSON.stringify(obj));
  return "";
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export const addItem = (array, value) => {
  array.push(value);
};

export const removeArrayItem = (array, value) => {
  const index = array.indexOf(value);
  if (index > -1) {
    array.splice(index, 1);
  }
};

export function getClosedWorkingLines(workingLines) {
  return workingLines.filter(
    (line) =>
      line.getHorizontalWorkingLine() &&
      line.getVerticalWorkingLine() &&
      line.getDiagonalWorkingLine()
  );
}

export const isString = (myVar) =>
  typeof myVar === "string" || myVar instanceof String;

export const checkSignature = (base64Str) =>
  !!base64Str.startsWith("data:application/json;base64");

export const decodeBase64 = (encodedStr) => atob(encodedStr.substring(29));

export const encodeBase64UTF8 = (str) =>
  window.btoa(unescape(encodeURIComponent(str)));

export const decodeBase64UTF8 = (strB64) =>
  decodeURIComponent(escape(window.atob(strB64)));

export const nextUUID = () => uuidv4();

export const calcNumberOfDecimalPlaces = (numberX, numberY) => {
  if (numberX === undefined || numberY === undefined) return 2;
  return Math.max(numberX, numberY);
};

export const objectHasProperty = (object, attribute) =>
  Object.prototype.hasOwnProperty.call(object, attribute);

export const isInteger = (number) => number % 1 === 0;

export const localStorageHasItem = (key) => localStorage.getItem(key) !== null;

export const getNumberFormat = (fractionalDigits = 2) => {
  if (fractionalDigits === 0) return "0,0";
  return `0,0.${"0".repeat(fractionalDigits)}`;
};

export const formatNumber = (num, decimalPlaces) => {
  let fractionalDigits = decimalPlaces;
  if (!fractionalDigits) {
    fractionalDigits = countDecimalsPlaces(num);
  }
  if (localStorageHasItem("i18nextLng")) {
    const lang = localStorage.getItem("i18nextLng");
    if (lang.startsWith("de")) numeral.locale("de");
    else numeral.locale("en-gb");
  }
  const number = +num;
  if (fractionalDigits === 0) return numeral(number).format("0,0");
  const format = `0,0.${"0".repeat(fractionalDigits)}`;
  return numeral(number).format(format);
};

export const setDefaultNumberFormat = () => {
  if (localStorageHasItem("i18nextLng")) {
    if (localStorage.getItem("i18nextLng") === "en") {
      d3.formatDefaultLocale(localeEN);
    } else if (localStorage.getItem("i18nextLng") === "de") {
      d3.formatDefaultLocale(localeDE);
    }
  }
};

const downloadTxtFile = (value, name) => {
  const element = document.createElement("a");
  const file = new Blob([value], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = `${name}.json`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const downloadFile = (data, filename) => {
  const encodedString = encodeBase64UTF8(JSON.stringify(data));
  downloadTxtFile(encodedString, filename);
};

export const getBytesOfArray = (array) => {
  // eslint-disable-next-line
  const g = JSON.stringify(array).replace(/[\[\]\,\"]/g, "");
  return g.length;
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const isCombinedWorkingLinePossible = (userProjects, projectId) =>
  userProjects[projectId].combinedWorkingLines.isVariant1Available &&
  userProjects[projectId].combinedWorkingLines.isVariant2Available;

export const isCombinedWorkingLineVariant1Possible = (project) =>
  project.combinedWorkingLines.isVariant1Available;

export const isCombinedWorkingLineVariant2Possible = (project) =>
  project.combinedWorkingLines.isVariant2Available;

export const findLowestNumberNotInList = (n, xs) =>
  xs.indexOf(n) < 0 ? n : findLowestNumberNotInList(n + 1, xs);

export const countDecimalsPlaces = (value) => {
  if (Math.floor(value) === value) return 0;

  const str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  }
  if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

export const safeDivByZero = (numerator, denominator) => {
  if (denominator === 0) return 0;
  return numerator / denominator;
};

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

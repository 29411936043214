import React, { useEffect, useState } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import {
  checkSignature,
  decodeBase64UTF8,
  isObjectEmpty,
  isString,
  decodeBase64,
  storeObjectInLocalStorage,
} from "../helpers/util";
import Toast from "./Toast";
import { sendMessage } from "../subjects/general";

export default function FileUploadPage() {
  const { setData, setUserProjects, setProjectId, setFileName } = useData();
  const { push } = useHistory();
  const [files, setFiles] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const { t } = useTranslation();

  const handleAdd = (newFiles) => {
    const copiedFiles = newFiles.filter(
      (file) => !newFiles.find((f) => f.data === file.data)
    );
    setFiles([...newFiles, ...copiedFiles]);
  };

  const handleDelete = (deleted) => {
    setFiles(files.filter((f) => f !== deleted));
  };

  const isDataValid = (data) =>
    !isObjectEmpty(data) &&
    Object.prototype.hasOwnProperty.call(data, "projectDescription") &&
    Object.prototype.hasOwnProperty.call(data, "editors") &&
    Object.prototype.hasOwnProperty.call(data, "projects") &&
    Object.prototype.hasOwnProperty.call(data, "userProjects") &&
    Object.prototype.hasOwnProperty.call(data, "projectName") &&
    Object.prototype.hasOwnProperty.call(data, "version") &&
    Object.prototype.hasOwnProperty.call(data, "details");

  useEffect(() => {
    if (files.length > 0) {
      if (isString(files[0].data) && checkSignature(files[0].data)) {
        const decodedStr = decodeBase64(files[0].data);
        const decodedStrUTF8 = decodeBase64UTF8(decodedStr);
        const data = JSON.parse(decodedStrUTF8);
        if (isDataValid(data)) {
          if (
            data.userProjects &&
            Object.entries(data.userProjects).length > 0
          ) {
            setShowSpinner(true);
            localStorage.removeItem("userProjects");
            localStorage.removeItem("projectId");
            setData(data);
            storeObjectInLocalStorage("userProjects", data.userProjects);
            localStorage.setItem("projectId", "0");
            localStorage.setItem("filename", data.projectName);
            setFileName(data.projectName);
            setUserProjects(data.userProjects);
            setProjectId(0); // we set the project id to the first diagram entry
            push("/project");
          } else {
            sendMessage(
              "The file you uploaded does not contain a user project!"
            );
            console.error(
              "The file you uploaded does not contain a user project!"
            );
            setFiles([]);
          }
        } else {
          sendMessage("The file you uploaded is not a valid project file!");
          console.error("The file you uploaded is not a valid project file!");
          setFiles([]);
        }
      } else {
        sendMessage("The file you uploaded is not a valid project file!");
        console.error("The file you uploaded is not a valid project file!");
        setFiles([]);
      }
    }
  }, [files, push, setData, setFileName, setProjectId, setUserProjects]);

  return (
    <>
      <Toast />
      {showSpinner && <CircularProgress size={52} />}
      <DropzoneAreaBase
        fileObjects={files}
        onAdd={handleAdd}
        onDelete={handleDelete}
        acceptedFiles={["application/json"]}
        filesLimit={1}
        dropzoneText={t("DropZone_Text")}
        maxFileSize={1_000_000_000}
      />
    </>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import DE from "./locales/de";
import EN from "./locales/en";

const detectionOptions = {
  order: ["querystring", "localStorage"],
  lookupFromPathIndex: 0,
  lookupQuerystring: "lang",
  lookupLocalStorage: "i18nextLng",
};

/* eslint max-len: ["error", { "code": 100000 }] */
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    detection: detectionOptions,
    fallbackLng: "de",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: EN,
      },
      de: {
        translation: DE,
      },
    },
  });

export default i18n;

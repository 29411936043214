import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { PropTypes } from "prop-types";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useData } from "../../DataProvider";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const InformationPopper = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { close } = props;
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const { userProjects, projectId } = useData();
  const { typeNumber } = userProjects[projectId];

  function handleClickAway() {
    setOpen(false);
    close();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Popper
          open={open}
          anchorEl={ref.current}
          placement="bottom"
          transition
          style={{ width: 400 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper style={{ padding: 20, marginTop: 10, borderRadius: 20 }}>
                <Typography variant="h6" className={classes.typography}>
                  {t(`diagramTypes.type${typeNumber}.title`)}
                </Typography>
                <p style={{ whiteSpace: "pre-line" }}>
                  {t(`diagramTypes.type${typeNumber}.desc`)}
                </p>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});

InformationPopper.propTypes = {
  close: PropTypes.func.isRequired,
};

export default InformationPopper;

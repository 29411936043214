import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LZString from "lz-string";
import { documentToSVG, elementToSVG, inlineResources } from "dom-to-svg";
import exportIcon from "../../../icons/Export.svg";
import exportIconPng from "../../../images/Export.png";
import saveIcon from "../../../icons/Save.svg";
import saveIconPng from "../../../images/Save.png";
import textIcon from "../../../icons/Text.svg";
import textIconPng from "../../../images/Text.png";
import pdfIcon from "../../../icons/pdf-icon.jpg";
import { useData } from "../../DataProvider";
import InformationPopper from "./InformationPopper";
import DetailsDialog from "./DetailsDialog";
import {
  getBytesOfArray,
  readObjectFromLocalStorage,
} from "../../../helpers/util";
import {
  setLabelStatus,
  setSnappingStatus,
  setLineSnappingStatus,
  setSynchronousZooming,
  triggerTextField,
} from "../../subjects/toolbar_subjects";
import { resetZoomTriggerAll } from "../../subjects/d3Subjects";
import PlanesDialog from "./PlanesDialog";
import { sendMessage } from "../../../subjects/general";
import CircularSpinner from "../../ui-elements/CircularSpinner";

const imageStyle = {
  width: "100%",
  height: "27px",
  maxHeight: "100px",
  maxWidth: "24px",
};

const toolbarContainer = {
  height: "auto",
  width: "max-content",
  background: "white",
  boxShadow: "0px 3px 6px #00000029",
};

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "transparent",
    border: "none",
    borderRightStyle: "groove",
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    font: "normal normal bold 15px/17px Helvetica",
    textTransform: "capitalize",
  },
  select: {
    width: "19px",
    marginRight: "5px",
  },
}));

export default function MainToolbar({ openShowDialog, updateSuccessState }) {
  const childRefInfo = useRef();
  const childRefDetails = useRef();
  const childRefPlanes = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data,
    projectId,
    userProjects,
    setUserProjects,
    isOnSaving,
    setIsOnSaving,
    hasIconsReplaced,
    setHasIconsReplaced,
  } = useData();
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [showPlanesDialog, setShowPlanesDialog] = useState(false);
  const [showInformationPopper, setShowInformationAreaPopper] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const [isSnapping, setSnapping] = useState(false);
  const [isLineSnapping, setLineSnapping] = useState(false);

  const [isSyncZooming, setSyncZooming] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const labelText = showLabel
    ? t("Beschriftung_ausblenden")
    : t("Beschriftung_einblenden");
  const snappingText = isSnapping ? t("Snapping_ein") : t("Snapping_aus");
  const snappingToLineText = isLineSnapping
    ? t("Snapping_toLine_ein")
    : t("Snapping_toLine_aus");
  const syncZoomingText = isSyncZooming
    ? t("Sync_Zooming_ein")
    : t("Sync_Zooming_aus");
  const imageRef = useRef();
  const timeout = 5_000;

  const serializeSvg = async (svgId) => {
    const svgDocument = documentToSVG(document);
    const svgElement = elementToSVG(document.querySelector(svgId));
    await inlineResources(svgDocument.documentElement);
    return new XMLSerializer().serializeToString(svgElement);
  };

  function createMultilineTextBoxes(doc) {
    // magical support for multiline textfields in svg
    const textareaElements = document.getElementsByTagName("textarea");
    const textAreaArray = [...textareaElements].filter(
      (element) => element.id === "standard-multiline-flexible"
    );
    const svgNS = "http://www.w3.org/2000/svg";

    textAreaArray.forEach((element) => {
      // geting the parent element which has the position on it
      const positionElement =
        element.parentElement.parentElement.parentElement.parentElement;
      const { top, left } = positionElement.style;
      const { textContent } = element;
      const textLinesArray = textContent.split("\n");

      const svgText = document.createElementNS(svgNS, "text");
      svgText.setAttributeNS(null, "font-size", 16);
      svgText.setAttributeNS(
        null,
        "font-family",
        "Arial, Helvetica, sans-serif"
      );
      svgText.setAttributeNS(null, "fill", "#0064A7");
      svgText.setAttributeNS(null, "x", left);
      svgText.setAttributeNS(null, "y", top);
      // transformation because top and left are positions of the boxes and not the text itself
      svgText.setAttributeNS(null, "transform", "translate(0, 14)");

      textLinesArray.forEach((textLine, index) => {
        const tn = document.createTextNode(textLine);
        const ts = document.createElementNS(svgNS, "tspan");
        ts.setAttributeNS(null, "dy", index > 0 ? "1.2em" : 0);
        ts.setAttributeNS(null, "x", left);

        svgText.appendChild(ts);
        ts.appendChild(tn);
      });

      doc.childNodes[0].appendChild(svgText);
    });
  }

  const serializeMainSvg = useCallback(async (svgId) => {
    const svgDocument = documentToSVG(document);
    const svgElement = elementToSVG(document.querySelector(svgId));
    await inlineResources(svgDocument.documentElement);
    const xmlString = new XMLSerializer().serializeToString(svgElement);
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlString, "image/svg+xml");

    // Hide elements from SVG
    [
      "ContextMenuLabelRightSide_q1",
      "ContextMenuLabelRightSide_q4",
      "ContextMenuButtonRightSide_q1",
      "ContextMenuButtonRightSide_q4",
      //
      "ContextMenuLabelLeftSide_q3",
      "ContextMenuLabelLeftSide_q2",
      "ContextMenuButtonLeftSide_q2",
      "ContextMenuButtonLeftSide_q3",
      //
      "pulseCircle",
      //
      "span2",
      "span4",
      "span5",
      "span6",
      "span7",
      "span9",
      "span11",
      "span12",
      "span14",
      "span15",
      "span16",
      "span17",
      "span19",
      "span20",
      "span21",
      "span22",
    ].forEach((id) => {
      if (doc.getElementById(id)) {
        doc.getElementById(id).style.display = "none";
      }
    });

    createMultilineTextBoxes(doc);

    return new XMLSerializer().serializeToString(doc);
  }, []);

  const prepareSave = () => setIsOnSaving(true);

  const onExport = () => {
    const isCurrentDiagramValid =
      userProjects[projectId].dataUrls.every((url) => url.length > 0) &&
      userProjects[projectId].dataUrls.length === 5;

    if (isCurrentDiagramValid) {
      localStorage.setItem("pdfMode", "simplePage");
      openShowDialog();
    } else {
      sendMessage(t("validationMsg"));
      console.log("validation failed");
    }
  };
  const onDetails = () => setShowDetailsDialog(!showDetailsDialog);
  const onLayers = () => setShowPlanesDialog(!showPlanesDialog);
  const onInfos = () => setShowInformationAreaPopper(!showInformationPopper);
  const onPdfDownload = () => {
    localStorage.removeItem("pdfMode");
    const areAllDataUrlsValid =
      data && data.userProjects
        ? Object.values(data.userProjects).every(
            (diagram) => diagram.dataUrls.length > 0
          )
        : false;

    const isCurrentDiagramValid =
      userProjects[projectId].dataUrls.every((url) => url.length > 0) &&
      userProjects[projectId].dataUrls.length === 5;

    if (areAllDataUrlsValid) {
      openShowDialog();
    } else if (!isCurrentDiagramValid) {
      sendMessage(t("validationMsg"));
      console.log("validation failed");
    } else {
      sendMessage(t("ToolTip_Pdf_Button_Disabled"));
      console.log("validation failed");
    }
  };

  const onLabel = () => {
    userProjects[projectId].showLabels = !showLabel;
    setUserProjects({ ...userProjects });
    setShowLabel(!showLabel);
    setLabelStatus(!showLabel);
  };

  const onSnappingToLine = () => {
    setLineSnapping(!isLineSnapping);
    setLineSnappingStatus(!isLineSnapping);
  };

  const onSnapping = () => {
    setSnapping(!isSnapping);
    setSnappingStatus(!isSnapping);
  };

  const onZooming = () => {
    setSyncZooming(!isSyncZooming);
    setSynchronousZooming(!isSyncZooming);
  };

  useEffect(() => {
    const onSave = async () => {
      localStorage.removeItem("pdfMode");

      resetZoomTriggerAll();

      const newUserProjects = readObjectFromLocalStorage("userProjects");
      setShowSpinner(true);

      newUserProjects[projectId].dataUrls[0] = LZString.compress(
        await serializeMainSvg("#quadrantPanel")
      );
      newUserProjects[projectId].dataUrls[1] = LZString.compress(
        await serializeSvg("#svg_quadrant_1")
      );
      newUserProjects[projectId].dataUrls[2] = LZString.compress(
        await serializeSvg("#svg_quadrant_2")
      );
      newUserProjects[projectId].dataUrls[3] = LZString.compress(
        await serializeSvg("#svg_quadrant_3")
      );
      newUserProjects[projectId].dataUrls[4] = LZString.compress(
        await serializeSvg("#svg_quadrant_4")
      );

      const sumOfBytes = Object.values(newUserProjects).reduce(
        (sum, project) => sum + getBytesOfArray(project.dataUrls),
        0
      );
      console.log(`Size all data urls: ${sumOfBytes / 1024} KBytes`);
      const diff = 5 * 1024 * 1024 - sumOfBytes;
      if (diff < 512 * 1024) {
        alert(
          `Warning: there is only about ${diff} Bytes of free browser disc space left`
        );
      }

      setUserProjects({ ...newUserProjects });
      setShowSpinner(false);
      updateSuccessState(true);
      setTimeout(() => updateSuccessState(false), timeout);
      setIsOnSaving(false);
      setHasIconsReplaced(false);
    };

    setShowLabel(userProjects[projectId].showLabels);

    if (isOnSaving && hasIconsReplaced) {
      onSave();
    }
  }, [
    hasIconsReplaced,
    isOnSaving,
    projectId,
    setHasIconsReplaced,
    setIsOnSaving,
    setUserProjects,
    updateSuccessState,
    serializeMainSvg,
  ]);

  return (
    <>
      <div style={toolbarContainer}>
        <form>
          <Tooltip title={t("ToolTip_Projekt_speichern")}>
            <button
              type="button"
              className={classes.btn}
              onClick={prepareSave}
              style={{ borderRightStyle: "none" }}
            >
              {showSpinner ? (
                <CircularSpinner />
              ) : (
                <img
                  src={isOnSaving ? saveIconPng : saveIcon}
                  alt="diagramIcon"
                  style={imageStyle}
                />
              )}
            </button>
          </Tooltip>
          <Tooltip title={t("ToolTip_PDF_Download")}>
            <button
              type="button"
              className={classes.btn}
              onClick={onPdfDownload}
              style={{ borderLeftStyle: "groove" }}
            >
              <img src={pdfIcon} alt="diagramIcon" style={imageStyle} />
            </button>
          </Tooltip>
          <Tooltip title={t("ToolTip_Screenshot_erstellen")}>
            <button type="button" className={classes.btn} onClick={onExport}>
              <img
                src={isOnSaving ? exportIconPng : exportIcon}
                alt="diagramIcon"
                style={imageStyle}
              />
            </button>
          </Tooltip>
          <Tooltip title={t("ToolTip_TextBox_erstellen")}>
            <button
              type="button"
              className={classes.btn}
              onClick={() => triggerTextField()}
            >
              <img
                src={isOnSaving ? textIconPng : textIcon}
                alt="diagramIcon"
                style={imageStyle}
              />
            </button>
          </Tooltip>
          <Tooltip title={t("Tooltip_Projektdetails_anzeigen")}>
            <button
              ref={childRefDetails}
              type="button"
              className={classes.btn}
              onClick={onDetails}
              style={{ verticalAlign: "super" }}
            >
              details
            </button>
          </Tooltip>
          <Tooltip title={t("Tooltip_Ebenen_anzeigen")}>
            <button
              ref={childRefPlanes}
              type="button"
              className={classes.btn}
              onClick={onLayers}
              style={{ verticalAlign: "super" }}
            >
              {t("Ebenen")}
            </button>
          </Tooltip>
          <Tooltip title={t("Tooltip_Projektinfos_anzeigen")}>
            <button
              ref={childRefInfo}
              type="button"
              className={classes.btn}
              onClick={onInfos}
              style={{ verticalAlign: "super" }}
            >
              information
            </button>
          </Tooltip>
          <Tooltip title={t("ToolTip_Beschriftung")}>
            <button
              type="button"
              className={classes.btn}
              onClick={onLabel}
              style={{ verticalAlign: "super" }}
            >
              {labelText}
            </button>
          </Tooltip>
          <Tooltip title={t("Text_Snapping")}>
            <button
              type="button"
              className={classes.btn}
              onClick={onSnapping}
              style={{ verticalAlign: "super" }}
            >
              {snappingText}
            </button>
          </Tooltip>
          <Tooltip title={t("Text_Snapping_toLine")}>
            <button
              type="button"
              className={classes.btn}
              onClick={onSnappingToLine}
              style={{ verticalAlign: "super" }}
            >
              {snappingToLineText}
            </button>
          </Tooltip>
          <Tooltip title={t("Text_Sync_Zooming")}>
            <button
              type="button"
              className={classes.btn}
              onClick={onZooming}
              style={{ verticalAlign: "super" }}
            >
              {syncZoomingText}
            </button>
          </Tooltip>
        </form>
      </div>
      {showInformationPopper && (
        <InformationPopper
          ref={childRefInfo}
          close={() => setShowInformationAreaPopper(false)}
        />
      )}
      {showDetailsDialog && (
        <DetailsDialog
          ref={childRefDetails}
          close={() => setShowDetailsDialog(false)}
        />
      )}
      {showPlanesDialog && (
        <PlanesDialog
          ref={childRefPlanes}
          close={() => setShowPlanesDialog(false)}
        />
      )}
      <img alt="" ref={imageRef} />
    </>
  );
}

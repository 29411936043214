import { makeStyles } from "@material-ui/core";
import React from "react";
import ContextMenu from "../graphs/settings/ContextMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    //position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    transform: "translate(0px,-30px)",
    marginBottom: "-5%",
    //paddingLeft: "18%",
    //paddingRight: "18%",
  },
  label: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "steelblue",
    background: "none",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
    transform: "translateX(25px)",
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    width: "100%",
    height: "100%",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexShrink: 0,
    transform: "translateX(10px)",
    margin: 0,
  },
  spacer: {
    width: "10px",
  },
  invisibleButton: {
    width: "100%",
    height: 30,

    background: "none",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
  },
}));

export default function MiddleAxis({
  areXAxisCongruent,
  userProjects,
  projectId,
  onLabelClick,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <button
          id="textField_horizontal_left"
          className={classes.label}
          type="button"
          onClick={() => onLabelClick(null, { index: 1 })}
        >
          {userProjects[projectId].quadrants[1].x.xAxisLabel}
        </button>
      </div>

      <div className={classes.centerContainer}>
        <div>
          <ContextMenu name="QUADRANT 2" isOnLeftSide qNumber={2} />
          <ContextMenu name="QUADRANT 3" isOnLeftSide qNumber={3} />
        </div>
        <div className={classes.spacer} />
        <div>
          <ContextMenu name="QUADRANT 1" isOnLeftSide={false} qNumber={1} />
          <ContextMenu name="QUADRANT 4" isOnLeftSide={false} qNumber={4} />
        </div>
      </div>

      <div className={classes.labelContainer}>
        {
          // workaround!: in case of areXAxisCongruent === false two buttons trigger the open Menue func
          // reason for this is the absolute position of MiddleAxis-Component overlapping the svg quadrant x-labels
          areXAxisCongruent ? (
            <button
              id="textField_horizontal_right"
              className={classes.label}
              type="button"
              onClick={() => onLabelClick(null, { index: 0 })}
            >
              {userProjects[projectId].quadrants[0].x.xAxisLabel}
            </button>
          ) : (
            <div>
              <button
                type="button"
                className={classes.invisibleButton}
                onClick={() => onLabelClick(null, { index: 0 })}
              >
                {" "}
              </button>
              <button
                type="button"
                className={classes.invisibleButton}
                onClick={() => onLabelClick(null, { index: 3 })}
              >
                {" "}
              </button>
            </div>
          )
        }
      </div>
    </div>
  );
}

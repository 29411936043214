import PropTypes from "prop-types";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  localStorageHasItem,
  readObjectFromLocalStorage,
  storeObjectInLocalStorage,
} from "../helpers/util";
import { setSelectedWorkingLines } from "./subjects/calculationSubjects";

const DataContext = createContext();

export default function DataProvider({ children }) {
  const projectIdLocalStorage = localStorageHasItem("projectId")
    ? localStorage.getItem("projectId")
    : 0;
  const dataLocalStorage = localStorageHasItem("data")
    ? readObjectFromLocalStorage("data")
    : null;
  const userProjectsLocalStorage = localStorageHasItem("userProjects")
    ? readObjectFromLocalStorage("userProjects")
    : null;
  const { t } = useTranslation();
  const [data, setData] = useState(dataLocalStorage);
  const [userData, setUserData] = useState({});
  const [projectId, setProjectId] = useState(projectIdLocalStorage);
  const [userProjects, setUserProjects] = useState(userProjectsLocalStorage);
  const [accordionList, setAccordionList] = useState([]);
  const [selectedPlaneItem, setSelectedPlaneItem] = useState();
  const [selectedPlane, setSelectedPlane] = useState();
  const [planeItems, setPlaneItems] = useState(
    parsePlaneConfigAttribute("planeItems")
  );
  const [topPlane, setTopPlane] = useState(
    userProjects ? userProjects[projectId]?.planeConfig?.topPlane || 0 : 0
  );
  const numberOfPlaneAccordions = useMemo(
    () =>
      userProjects
        ? userProjects[projectId]?.planeConfig?.numberOfAccordions || 0
        : 0,
    [projectId, userProjects]
  );
  const [showVisibilitiesLevelItems, setShowVisibilitiesLevelItems] = useState(
    []
  );
  //TODO: check why only setUser is needed
  //eslint-disable-next-line
  const [user, setUser] = useState(null);
  const [location, setLocation] = useState("en"); // needed to enforce re-rendering in chart component
  const filenameLocalStorage = localStorageHasItem("filename")
    ? localStorage.getItem("filename")
    : { ...data?.projectName };
  const [fileName, setFileName] = useState(filenameLocalStorage);
  const [svgs, setSvgs] = useState([null, null, null, null]);
  const [isStudentVersion, setIsStudentVersion] = useState(true);
  const [detailsInformation, setDetailsInformation] = useState(
    userProjects && userProjects[projectId]?.detailsInformation
  );
  const [planeTitle, setPlaneTitle] = useState(
    userProjects && userProjects[projectId]?.planeConfig?.title !== ""
      ? userProjects[projectId]?.planeConfig.title
      : t("Ebenen")
  );
  const [accordionStates, setAccordionStates] = useState(
    parsePlaneConfigAttribute("accordionStates")
  );
  const [isOnSaving, setIsOnSaving] = useState(false);
  const [hasIconsReplaced, setHasIconsReplaced] = useState(false);

  let quadrants = [];

  if (data) data.userProjects = userProjects;

  function addQuadrant(quadrant) {
    quadrants.push(quadrant);
  }

  function deleteQuadrants() {
    quadrants = [];
  }

  function parsePlaneConfigAttribute(configAttribute) {
    if (
      userProjects &&
      userProjects[projectId] &&
      Object.prototype.hasOwnProperty.call(
        userProjects[projectId],
        "planeConfig"
      )
    ) {
      return userProjects[projectId].planeConfig[configAttribute] || [];
    }
    return [];
  }

  const getUserProjectsLS = () => {
    return readObjectFromLocalStorage("userProjects");
  };

  useEffect(() => {
    if (data) {
      storeObjectInLocalStorage("data", data);
    } else if (localStorageHasItem("data")) {
      const readDataObject = readObjectFromLocalStorage("data");
      setData(readDataObject);
    }
  }, [data]);

  useEffect(() => {
    if (fileName && fileName.length > 0) {
      localStorage.setItem("filename", fileName);
    }
  }, [fileName]);

  useEffect(() => {
    if (userProjects) {
      storeObjectInLocalStorage("userProjects", userProjects);
    } else if (localStorageHasItem("userProjects")) {
      const readObject = readObjectFromLocalStorage("userProjects");
      setUserProjects(readObject);
    }
  }, [userProjects]);

  useEffect(() => {
    const selectedWorkingLines = [];
    if (!planeItems || planeItems.length === 0) return;
    planeItems.forEach((element) => {
      const filteredArray = element.filter((el) => el.isChecked === true);
      filteredArray.forEach((el) => {
        const workingLineIndex = Number(el.id.substring(2));
        selectedWorkingLines.push(workingLineIndex);
      });
    });
    setSelectedWorkingLines(selectedWorkingLines);
  }, [planeItems]);

  useEffect(() => {
    if (!localStorageHasItem("data")) storeObjectInLocalStorage("data", data);
  }, [data]);

  DataProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        userProjects,
        setUserProjects,
        projectId,
        setProjectId,
        quadrants,
        addQuadrant,
        deleteQuadrants,
        accordionList,
        setAccordionList,
        planeItems,
        setPlaneItems,
        setSelectedPlaneItem,
        setSelectedPlane,
        selectedPlaneItem,
        selectedPlane,
        topPlane,
        setTopPlane,
        numberOfPlaneAccordions,
        showVisibilitiesLevelItems,
        setShowVisibilitiesLevelItems,
        setUser,
        location,
        setLocation,
        fileName,
        setFileName,
        svgs,
        setSvgs,
        isStudentVersion,
        setIsStudentVersion,
        planeTitle,
        setPlaneTitle,
        accordionStates,
        setAccordionStates,
        getUserProjectsLS,
        detailsInformation,
        setDetailsInformation,
        isOnSaving,
        setIsOnSaving,
        hasIconsReplaced,
        setHasIconsReplaced,
        userData,
        setUserData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export function useData() {
  return React.useContext(DataContext);
}

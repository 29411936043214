import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import { openToolbarListener } from "../../subjects/d3Subjects";
import deleteIcon from "../../../icons/DeleteTrashbin.svg";
import xyIcon from "../../../icons/XY_Arbeitslinie.svg";
import changeColorIcon from "../../../icons/ChangeColor.svg";
import noLineIcon from "../../../icons/line_cross_out_red.svg";
import stroke55Icon from "../../../icons/stroke_5_5.svg";
import stroke1010Icon from "../../../icons/stroke_10_10.svg";
import strokeVariable from "../../../icons/stroke_20_10_5_5_5_1.svg";
import WorkingLine from "../chartFunctions/WorkingLine";
import ColorPickerDialog from "./ColorPickerDialog";
import AreaOfTextFields from "./AreaOfTextFields";
import ArrowMenu from "./ArrowMenu";
import { Label, LabelOff, RemoveRedEye, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const imageStyle = {
  width: "100%",
  height: 44,
  maxHeight: 100,
  maxWidth: 40,
};

const toolbarContainer = {
  height: "auto",
  width: "max-content",
  background: "rgb(239 239 239 / 80%)",
  boxShadow: "0px 3px 6px #00000029",
};

const getImageRotationDegrees = (instance) => {
  if (instance?.isFirstQuadrant) return 0;
  if (instance?.isFourthQuadrant) return 90;
  if (instance?.isThirdQuadrant) return 180;
  return 270;
};

export default function MiniToolbar() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [stroke, setStroke] = useState(0);
  const [strokeWidth, setStrokeWidth] = useState(3);
  const [selectedArrowDirection, setSelectedArrowDirection] = useState(5);
  const [workingLineInstance, setWorkingLineInstance] = useState(null);
  const [mouseX, setMouseX] = useState(0);
  const [showLabel, setWhowLabel] = useState(true);
  const [stickTheLabel, setStickTheLabel] = useState(true);
  const [mouseY, setMouseY] = useState(0);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showArea, setShowArea] = useState(false);
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [tooltipStates, setTooltipStates] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  let offsetX = 0;
  const offsetY = -111;
  const quadrant = workingLineInstance?.quadrant;
  const qNumber = workingLineInstance?.quadrant.number;
  const imageRotation = getImageRotationDegrees(workingLineInstance);

  if (qNumber === 1) {
    offsetX = -550;
  } else if (qNumber === 2) {
    offsetX = -100;
  } else if (qNumber === 3) {
    offsetX = -100;
  } else if (qNumber === 4) {
    offsetX = -550;
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "transparent",
    },
    paper: {
      backgroundColor: "transparent",
      boxShadow: "none",
      overflow: "hidden",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
    },
    dialog: {
      position: "absolute",
      left: mouseX + offsetX,
      top: mouseY + offsetY,
    },
    btn: {
      backgroundColor: "transparent",
      border: "none",
      borderRightStyle: "groove",
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      fontWeight: 500,
      font: "normal normal bold 15px/17px Helvetica",
      textTransform: "uppercase",
    },
    select: {
      width: "19px",
      marginRight: "5px",
    },
  }));

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!showArea) setOpen(false);
  };

  openToolbarListener().subscribe((object) => {
    const workingLine = object.selfRef;
    setWorkingLineInstance(workingLine);
    setName(workingLine.name);
    setStroke(workingLine.strokeIndex);
    setStrokeWidth(workingLine.strokeWidth);
    setSelectedArrowDirection(workingLine.arrowDirectionIndex);
    setDecimalPlaces(workingLine.decimalPlaces);
    setMouseX(object.mouseX);
    setMouseY(object.mouseY);
    handleClickOpen();
  });

  const copyStyleWorkingLine = (orig, dest) => {
    if (orig && dest) {
      dest.setColor(orig.color);
      dest.setStroke(orig.strokeIndex);
      dest.setStrokeWidth(orig.strokeWidth);
      dest.setName(orig.name);
    }
  };

  const copyStyleToAllQuadrants = () => {
    const workingLineHorizontal =
      workingLineInstance.getHorizontalWorkingLine();
    const workingLineVertical = workingLineInstance.getVerticalWorkingLine();
    const workingLineDiagonal = workingLineInstance.getDiagonalWorkingLine();

    copyStyleWorkingLine(workingLineInstance, workingLineHorizontal);
    copyStyleWorkingLine(workingLineInstance, workingLineVertical);
    copyStyleWorkingLine(workingLineInstance, workingLineDiagonal);
  };

  const updateColorOfWorkingLine = (color) => {
    workingLineInstance.setColor(color);
    copyStyleToAllQuadrants();
  };

  const keyPressed = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      workingLineInstance.setName(event.target.value);
      closeArea();
    }
  };

  const onXYChange = () => {
    setShowArea(!showArea);
  };

  const handleChangeTextField = (event) => {
    setName(event.target.value);
    workingLineInstance.setName(event.target.value);
    copyStyleToAllQuadrants();
  };

  const onSelectStroke = (event) => {
    setStroke(event.target.value);
    workingLineInstance.setStroke(event.target.value);
    copyStyleToAllQuadrants();
  };

  const onSelectStrokeWidth = (event) => {
    setStrokeWidth(event.target.value);
    workingLineInstance.setStrokeWidth(event.target.value);
    copyStyleToAllQuadrants();
  };

  const onSelectDecimalPlaces = (event) => {
    setDecimalPlaces(event.target.value);
    workingLineInstance.setDecimalPlaces(event.target.value);
  };

  function closeArea() {
    setShowArea(false);
    setOpen(false);
  }

  const onDelete = () => {
    WorkingLine.deleteWorkingLine(workingLineInstance);
    closeArea();
  };

  const handleChangeColor = () => {
    setShowColorPicker(true);
  };

  const updateWorkingLinePosition = (valX, valY) => {
    let x = valX;
    let y = valY;

    if (valX > quadrant.xMax) x = quadrant.xMax;
    if (valX < quadrant.xMin) x = quadrant.xMin;
    if (valY > quadrant.yMax) y = quadrant.yMax;
    if (valY < quadrant.yMin) y = quadrant.yMin;
    if (Number(valX) === quadrant.xMin && Number(valY) === quadrant.yMin)
      return;

    workingLineInstance.updateWorkingLineByValues(x, y);
  };

  const onMenuItemChanged = (value) => {
    workingLineInstance.setArrowDirection(value);
  };

  const disableTooltipState = (index) => {
    tooltipStates[index] = false;
    setTooltipStates([...tooltipStates]);
  };

  const hideLines = () => {
    workingLineInstance.hideLabel(!showLabel);
    setWhowLabel(!showLabel);
  };

  const stickLabel = () => {
    workingLineInstance.stickLabel(!stickTheLabel);
    setStickTheLabel(!stickTheLabel);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        classes={{
          paper: classes.dialog,
        }}
        BackdropProps={{
          classes: {
            root: classes.root,
          },
        }}
        PaperProps={{
          classes: {
            root: classes.paper,
          },
        }}
      >
        <DialogContent>
          <div style={toolbarContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title={t("ToolTip_Name_Arbeitslinie")} placement="top">
                <TextField
                  value={name}
                  id="toolbox-textField"
                  label="Name"
                  onKeyDown={keyPressed}
                  onChange={handleChangeTextField}
                />
              </Tooltip>
              <Tooltip title={t("ToolTip_Koordinaten")} placement="top">
                <button
                  type="button"
                  className={classes.btn}
                  onClick={onXYChange}
                  style={{ borderRightStyle: "none" }}
                >
                  <img src={xyIcon} alt="diagramIcon" style={imageStyle} />
                </button>
              </Tooltip>
              <ArrowMenu
                selectedItem={selectedArrowDirection}
                onSelected={onMenuItemChanged}
                imageRotation={imageRotation}
              />
              <Tooltip title={t("ToolTip_Farbe")} placement="top">
                <button
                  type="button"
                  className={classes.btn}
                  onClick={handleChangeColor}
                  style={{ borderRightStyle: "none" }}
                >
                  <img
                    src={changeColorIcon}
                    alt="diagramIcon"
                    style={imageStyle}
                  />
                </button>
              </Tooltip>
              <Tooltip
                title={t("ToolTip_Strichstil")}
                placement="top"
                onClick={() => disableTooltipState(4)}
                disableHoverListener={!tooltipStates[4]}
              >
                <FormControl>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select-1"
                    value={stroke}
                    onChange={onSelectStroke}
                    style={{ width: 100 }}
                  >
                    <MenuItem value={0} style={{ width: 100 }}>
                      <img src={noLineIcon} alt="no line icon" />
                    </MenuItem>
                    <MenuItem value={1}>
                      <img src={stroke55Icon} alt="stroke line 1 icon" />
                    </MenuItem>
                    <MenuItem value={2}>
                      <img src={stroke1010Icon} alt="stroke line 2 icon" />
                    </MenuItem>
                    <MenuItem value={3}>
                      <img src={strokeVariable} alt="stroke line 3 icon" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
              <Tooltip
                title={t("ToolTip_Strichstaerke")}
                placement="top"
                onClick={() => disableTooltipState(5)}
                disableHoverListener={!tooltipStates[5]}
              >
                <FormControl style={{ marginLeft: 5 }}>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select-2"
                    value={strokeWidth}
                    onChange={onSelectStrokeWidth}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
              <Tooltip
                title={t("ToolTip_Anzahl_Nachkomma")}
                placement="top"
                onClick={() => disableTooltipState(6)}
                disableHoverListener={!tooltipStates[6]}
              >
                <FormControl style={{ marginLeft: 5 }}>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select-3"
                    value={decimalPlaces}
                    onChange={onSelectDecimalPlaces}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
              <Tooltip
                title={t("ToolTip_Arbeitslinie_loeschen")}
                placement="top"
              >
                <button
                  type="button"
                  className={classes.btn}
                  onClick={onDelete}
                  style={{ borderRightStyle: "none" }}
                >
                  <img src={deleteIcon} alt="diagramIcon" style={imageStyle} />
                </button>
              </Tooltip>
              <Tooltip
                title={
                  !showLabel
                    ? t("Beschriftung_einblenden")
                    : t("Beschriftung_ausblenden")
                }
                placement="top"
              >
                <IconButton onClick={hideLines}>
                  {!showLabel ? <VisibilityOff /> : <RemoveRedEye />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  !stickTheLabel
                    ? t("Label_stick")
                    : t("Label_free")
                }
                placement="top"
              >
                <IconButton onClick={stickLabel}>
                  {!stickTheLabel ? <Label /> : <LabelOff />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <ColorPickerDialog
            show={showColorPicker}
            currentColor={workingLineInstance?.color}
            close={(color) => {
              setShowColorPicker(false);
              updateColorOfWorkingLine(color);
            }}
          />
          {showArea ? (
            <AreaOfTextFields
              name={workingLineInstance.name}
              valueX={workingLineInstance.valueXAxis}
              valueY={workingLineInstance.valueYAxis}
              decimalPlaces={decimalPlaces}
              updatePosition={updateWorkingLinePosition}
              close={closeArea}
              enabled={!workingLineInstance.isCombined}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

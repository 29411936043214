import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ConfigTabs from "./ConfigTabPanel";
import { useData } from "../../DataProvider";
import {
  readObjectFromLocalStorage,
  storeObjectInLocalStorage,
} from "../../../helpers/util";

export default function DiagramConfigDialog({ isVisible, close, qNumber }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setUserProjects, deleteQuadrants } = useData();
  const { projectId } = useData();
  const { t } = useTranslation();
  let valuesXAxis;
  let valuesYAxis;
  let valuesDataSeries;
  let numberDecimalPlaces;

  DiagramConfigDialog.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    qNumber: PropTypes.number.isRequired,
  };

  const saveValuesInDataProvider = () => {
    const newUserProjects = readObjectFromLocalStorage("userProjects");
    const arrayOfQuadrants = newUserProjects[projectId].quadrants;
    arrayOfQuadrants.forEach((quadrant, index) => {
      Object.assign(quadrant.x, valuesXAxis?.quadrants[index]);
      Object.assign(quadrant.y, valuesYAxis?.quadrants[index]);
      Object.assign(quadrant.dataseries, valuesDataSeries?.quadrants[index]);
      // eslint-disable-next-line no-param-reassign
      quadrant.fractionalDigits = numberDecimalPlaces[index];
    });

    storeObjectInLocalStorage("userProjects", newUserProjects);
    setUserProjects(newUserProjects);
  };

  const resetStates = () => {
    valuesXAxis = {};
    valuesYAxis = {};
    valuesDataSeries = {};
    numberDecimalPlaces = [];
  };

  const handleClose = () => {
    resetStates();
    close();
  };

  const onSave = () => {
    saveValuesInDataProvider();
    deleteQuadrants();
    close();
  };

  useEffect(() => {
    setOpen(isVisible);
  }, [isVisible]);

  const updateValues = (
    xAxisObj,
    yAxisObj,
    dataSeriesObj,
    numberDecimalPlacesArray
  ) => {
    valuesXAxis = xAxisObj;
    valuesYAxis = yAxisObj;
    valuesDataSeries = dataSeriesObj;
    numberDecimalPlaces = numberDecimalPlacesArray;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          {t("Ueberschrift_Diagramm_anpassen")}
        </DialogTitle>
        <DialogContent>
          <ConfigTabs qNumber={qNumber} onChanged={updateValues} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t("Dialog_Abbrechen")}
          </Button>
          <Button onClick={onSave} color="primary" autoFocus>
            {t("Dialog_Anwenden")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

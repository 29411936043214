import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core/";
import SpecialAnnotation from "./SpecialAnnotation";

const buildingTypes = [
  "Spezialfeld_Auswahl_0",
  "Spezialfeld_Auswahl_1",
  "Spezialfeld_Auswahl_2",
  "Spezialfeld_Auswahl_3",
  "Spezialfeld_Auswahl_4",
  "Spezialfeld_Auswahl_5",
  "Spezialfeld_Auswahl_6",
  "Spezialfeld_Auswahl_7",
  "Spezialfeld_Auswahl_8",
  "Spezialfeld_Auswahl_9",
  "Spezialfeld_Auswahl_10",
  "Spezialfeld_Auswahl_11",
  "Spezialfeld_Auswahl_12",
];

const SpecialAnnotationsList = (props) => {
  const { onListChanged, annotations, setAnnotations } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  // In case of old object data structure in localStorage,
  // set annotations to empty Array
  // NiceToHave: tranform old data to new structure
  useEffect(() => {
    if (Array.isArray(annotations) === false) {
      setAnnotations([]);
    }
  });

  // update InputField
  const updateAnnotation = (update) => {
    const updatedAnnotations = annotations.map((annotation) => {
      if (annotation.id === update.id) {
        return update;
      }
      return annotation;
    });

    setAnnotations(updatedAnnotations);
    onListChanged(true);
  };

  // delete InputField
  const deleteAnnotation = (annotationID) => {
    setAnnotations(annotations.filter((item) => item.id !== annotationID));
    onListChanged(true);
  };

  // create new InputField
  const addAnnotation = () => {
    const newAnnotation = {
      id: new Date().valueOf(),
      seleced: "",
      amount: "",
    };
    setAnnotations([...annotations, newAnnotation]);
    onListChanged(true);
  };

  const renderAnnotations = () => {
    if (Array.isArray(annotations) === true) {
      return annotations.map((annotation, i) => {
        return (
          <SpecialAnnotation
            key={annotation.id}
            id={annotation.id}
            fields={buildingTypes}
            selected={annotation.selected}
            amount={annotation.amount}
            onDelete={deleteAnnotation}
            onUpdate={updateAnnotation}
          />
        );
      });
    }
  };

  return (
    <div className={classes.container}>
      {renderAnnotations()}
      <Button
        className={classes.addButton}
        variant="contained"
        color="primary"
        onClick={addAnnotation}
      >
        + {t("Eingabefeld_hinzufuegen")}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  addButton: {
    maxWidth: "250px",
    margin: 20,
  },
}));

export default SpecialAnnotationsList;

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
  },
}));

function SimpleSelect({ numberOfItems, style, onChange, defaultValue }) {
  const { t } = useTranslation();
  const classes = useStyles();

  // TODO: check why default is not 1
  const defaultValueNormalized =
    defaultValue === 0 ? "1" : String(defaultValue);

  const [amount, setAmount] = useState(defaultValueNormalized);
  const handleChange = (event) => {
    setAmount(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div style={style}>
      <FormControl className={classes.formControl}>
        <InputLabel id="simple-select-amount-label">{t("Anzahl")}</InputLabel>
        <Select
          labelId="simple-select-amount-label"
          id="simple-select-amount"
          value={amount}
          onChange={handleChange}
        >
          {[...new Array(numberOfItems)]
            .map(() => " ++ irrelevant input ++")
            .map((el, index) => (
              <MenuItem key={index} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>{t("Anzahl_Geschosse")}</FormHelperText>
      </FormControl>
    </div>
  );
}

SimpleSelect.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  style: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
};

SimpleSelect.defaultProps = {
  defaultValue: "0",
};

export default SimpleSelect;

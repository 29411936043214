import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { PropTypes } from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: 300,
  },
}));

function TypeSelect({ fields, helperText, labelText, onChange, defaultValue }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [type, setType] = React.useState(defaultValue);

  const handleChangeSelectType = (event) => {
    setType(event.target.value);
    onChange(event);
  };
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="simple-select-type-label">{labelText}</InputLabel>
      <Select
        labelId="simple-select-type-label"
        id="simple-select-type"
        value={type}
        onChange={handleChangeSelectType}
        style={{ marginRight: 30 }}
      >
        {fields.map((val, i) => (
          <MenuItem key={i} value={val}>
            {t(val)}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{t(helperText)}</FormHelperText>
    </FormControl>
  );
}

TypeSelect.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  helperText: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

TypeSelect.defaultProps = {
  helperText: "",
  defaultValue: "",
};

export default TypeSelect;

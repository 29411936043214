import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core";
import iconBAP from "../../icons/Bauablaufplanung.svg";
import iconLW from "../../icons/Leistungswerte.svg";
import iconEL from "../../icons/Entsorgungslogistik.svg";
import iconBL from "../../icons/Baustellenlogistik.svg";
import DiagramTypeInfoDialog from "./DiagramTypeInfoDialog";
import { useData } from "../DataProvider";
import { isObjectEmpty, storeObjectInLocalStorage } from "../../helpers/util";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  centerPaper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  spaceBetweenPaper: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.secondary,
    height: "45px",
  },
  header: {
    textAlign: "center",
  },
}));

const StyledButton = withStyles({
  root: {
    fontSize: "0.75vw",
    lineHeight: 1,
  },
})(Button);

export default function DiagramTypeContent() {
  const { push } = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    data,
    setData,
    setProjectId,
    setUserProjects,
    userProjects,
    setDetailsInformation,
  } = useData();
  const [isReady, setIsReady] = useState(false);

  const getDiagramTypeByTypeNumber = (copyData, diagramTypeNumber) =>
    copyData.projects.find(
      (el) => el.typeNumber.toString() === diagramTypeNumber.toString()
    );

  const handleClick = (diagramTypeNumber) => {
    const copyData = JSON.parse(JSON.stringify(data));
    const diagramType = getDiagramTypeByTypeNumber(copyData, diagramTypeNumber);

    if (!diagramType) return;

    if (userProjects && !isObjectEmpty(userProjects)) {
      const nextIndex =
        +Object.keys(userProjects).reduce((a, b) => Math.max(a, b)) + 1;

      userProjects[nextIndex] = diagramType;
      userProjects[nextIndex].title = diagramType.title;
      copyData.userProjects = userProjects;

      setProjectId(nextIndex);
      setUserProjects(userProjects);
      setData(copyData);
      localStorage.setItem("projectId", `${nextIndex}`);

      // resetting details for DetailsDialog
      setDetailsInformation(
        copyData.userProjects[nextIndex].detailsInformation
      );
    } else {
      copyData.userProjects = [];
      copyData.userProjects.push(diagramType);
      copyData.userProjects[0].title = diagramType.title;
      setProjectId(0);
      setUserProjects(copyData.userProjects);
      setData(copyData);
      localStorage.setItem("projectId", "0");

      setDetailsInformation(copyData.userProjects[0].detailsInformation);
    }

    storeObjectInLocalStorage("userProjects", copyData.userProjects);
    setIsReady(true);
  };

  useEffect(() => {
    if (isReady) push("/chart");
  }, [isReady, push]);

  return (
    <>
      <h2 className={classes.header}>{t("Header_Diagrammtyp")}</h2>
      <div className={classes.root}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.centerPaper}>
              <img
                src={iconBAP}
                alt="shows button"
                style={{ maxWidth: "60px" }}
              />
              <div>{t("header1")}</div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.centerPaper}>
              <img
                src={iconLW}
                alt="shows button"
                style={{ maxWidth: "60px" }}
              />
              <div> {t("header2")}</div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.centerPaper}>
              <img
                src={iconEL}
                alt="shows button"
                style={{ maxWidth: "60px" }}
              />
              <div>{t("header3")}</div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.centerPaper}>
              <img
                src={iconBL}
                alt="shows button"
                style={{ maxWidth: "60px" }}
              />
              <div>{t("header4")}</div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("2")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "2").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "2")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("1_1")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "1_1").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "1_1")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("4")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "4").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "4")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("5")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "5").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "5")}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("11")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "11").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "11")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("1_2")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "1_2").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "1_2")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("6")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "6").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "6")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("8")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "8").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "8")}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("12")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "12").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "12")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("1_3")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "1_3").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "1_3")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("7")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "7").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "7")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("9")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "9").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "9")}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("17")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "17").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "17")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("1_4")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "1_4").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "1_4")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("13")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "13").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "13")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("1_5")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "1_5").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "1_5")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("14")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "14").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "14")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("3")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "3").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "3")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("15")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "15").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "15")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("10")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "10").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "10")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("16")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "16").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "16")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("11")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "11").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "11")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("17")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "17").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "17")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("12")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "12").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "12")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("18")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "18").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "18")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <Paper className={classes.spaceBetweenPaper}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => handleClick("19")}
                fullWidth
              >
                {getDiagramTypeByTypeNumber(data, "19").title}
              </StyledButton>
              <DiagramTypeInfoDialog
                diagram={getDiagramTypeByTypeNumber(data, "19")}
              />
            </Paper>
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
        </Grid>
      </div>
    </>
  );
}

import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../images/InterABC.png";
import NavigationBar from "./NavigationBar";
import { useData } from "../DataProvider";

const useStyles = makeStyles({
  container: {
    boxShadow: "0px 3px 6px #00000029",
    maxHeight: "75px",
    height: "48px",
  },
});

export default function Header({ isChart }) {
  const classes = useStyles();
  const { isOnSaving, setUserData } = useData();

  const logout = () => {
    localStorage.removeItem("token");
    setUserData(null);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={localStorage.getItem("token") ? 9 : 10}>
        {!isChart && <NavigationBar />}
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        item
        xs={localStorage.getItem("token") ? 3 : 2}
      >
        {!isOnSaving && <img src={logo} alt="diagramIcon" height="48px" />}
        <Typography variant="h6" style={{ marginLeft: "5%" }}>
          Interakt
        </Typography>
        {localStorage.getItem("token") && (
          <Button
            style={{ marginLeft: "8%", marginRight: "5%" }}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={logout}
          >
            Logout
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

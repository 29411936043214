import React, { createRef, useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  TextField,
  Paper,
} from "@material-ui/core/";

import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ContentEditable from "react-contenteditable";

import DeleteIcon from "@material-ui/icons/Delete";
import iconBAP from "../../icons/Bauablaufplanung.svg";
import iconBL from "../../icons/Baustellenlogistik.svg";
import iconEL from "../../icons/Entsorgungslogistik.svg";
import iconLW from "../../icons/Leistungswerte.svg";
import DiagramTypeDialog from "../../components/ui-elements/DiagramTypeDialog";
import PDFRenderer from "../../components/graphs/pdfDownload/PDFRenderer";
import TypeSelect from "../../components/ui-elements/TypeSelect";
import SimpleSelect from "../../components/ui-elements/SimpleSelect";
import DiagramIcon from "../../icons/Diagramm_icon.svg";
import { useData } from "../../components/DataProvider";
import {
  deepClone,
  downloadFile,
  isObjectEmpty,
  useQuery,
} from "../../helpers/util";
import SaveDialog from "../../components/ui-elements/SaveDialog";
import jsonData from "../../samples/data";
import QuestionDialog from "../../components/ui-elements/QuestionDialog";
import ContinueDialog from "../../components/ui-elements/ContinueDialog";
import DisabledButtonWithTooltip from "../../components/ui-elements/DisabledButtonWithTooltip";
import Header from "../../components/header/Header";
import SpecialAnnotationsList from "./components/SpecialAnnotationsList";

export default function ProjectPage() {
  const classes = useStyles();
  const {
    data,
    setData,
    userProjects,
    setUserProjects,
    setProjectId,
    setDetailsInformation,
  } = useData();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const defaultHeaderText = "Neues Projekt: Projektbeschreibung eingeben";
  const areAllDataUrlsValid =
    data && data.userProjects
      ? Object.values(data.userProjects).every(
          (diagram) => diagram.dataUrls.length > 0
        )
      : false;
  const contentEditable = createRef();

  const [createPDF, setCreatePDF] = useState(false);
  const [optionalInputFieldsState, setOptionalInputFieldsState] = useState({
    inputs: [],
  });

  const [isReady, setIsReady] = useState(false);
  const [showDiagramTypeDialog, setShowDiagramTypeDialog] = useState(false);
  const [headerText, setHeaderText] = useState(
    data ? data.projectName : defaultHeaderText
  );
  const [editors, setEditors] = useState(data?.editors);
  const [version, setVersion] = useState(data?.version);
  const [description, setDescription] = useState(data?.projectDescription);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [buildingType, setBuildingType] = useState(data?.details?.buildingType);
  const [amountFloors, setAmountFloors] = useState(data?.details?.floors);
  const [multipleSpecialFields, setMultipleSpecialFields] = useState(
    data?.details?.specialFields
  );

  const [enableExportProject, setEnableExportProject] = useState(false);
  const isNewProject = query.get("new") === "true" || false;
  const [openSaveQuestionDialog, setOpenSaveQuestionDialog] = useState(false);
  const [renderOnlyOnce, setRenderOnlyOnce] = useState(true);
  const [isSuccessfullyExported, setIsSuccessfullyExported] = useState(false);
  const [filename, setFilename] = useState(
    localStorage.getItem("filename")
      ? localStorage.getItem("filename")
      : "project.pdf"
  );
  const [isModified, setIsModified] = useState(false);
  const SUCCESS = 1;

  const initLangPack = useCallback(
    (projectTypes) => {
      if (projectTypes)
        projectTypes.forEach((type) => {
          type.title = t(`diagramTypes.type${type.typeNumber}.title`);
          type.description = t(`diagramTypes.type${type.typeNumber}.desc`);
          type.quadrants[0].legend.headerText = t(
            `diagramTypes.type${type.typeNumber}.Q1_DS_desc`
          );
          type.quadrants[0].x.xAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q1_X_desc`
          );
          type.quadrants[0].y.yAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q1_Y_desc`
          );
          type.quadrants[1].legend.headerText = t(
            `diagramTypes.type${type.typeNumber}.Q2_DS_desc`
          );
          type.quadrants[1].x.xAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q2_X_desc`
          );
          type.quadrants[1].y.yAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q2_Y_desc`
          );
          type.quadrants[2].legend.headerText = t(
            `diagramTypes.type${type.typeNumber}.Q3_DS_desc`
          );
          type.quadrants[2].x.xAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q3_X_desc`
          );
          type.quadrants[2].y.yAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q3_Y_desc`
          );
          type.quadrants[3].legend.headerText = t(
            `diagramTypes.type${type.typeNumber}.Q4_DS_desc`
          );
          type.quadrants[3].x.xAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q4_X_desc`
          );
          type.quadrants[3].y.yAxisLabel = t(
            `diagramTypes.type${type.typeNumber}.Q4_Y_desc`
          );
        });
    },
    [t]
  );

  const createNewDataStructure = useCallback(() => {
    initLangPack(jsonData.projects);
    setData(deepClone(jsonData));
    console.log(jsonData);
    localStorage.setItem("filename", jsonData.projectName);
    localStorage.setItem("langTriggered", "false");
  }, [initLangPack, setData]);

  if (!data) {
    createNewDataStructure();
  }

  if (isReady) {
    history.push("/chart");
  }

  const buildingTypes = [
    "Gebaeudetyp_Auswahl_0",
    "Gebaeudetyp_Auswahl_1",
    "Gebaeudetyp_Auswahl_2",
    "Gebaeudetyp_Auswahl_3",
    "Gebaeudetyp_Auswahl_4",
    "Gebaeudetyp_Auswahl_5",
    "Gebaeudetyp_Auswahl_6",
    "Gebaeudetyp_Auswahl_7",
    "Gebaeudetyp_Auswahl_8",
    "Gebaeudetyp_Auswahl_9",
    "Gebaeudetyp_Auswahl_10",
    "Gebaeudetyp_Auswahl_11",
    "Gebaeudetyp_Auswahl_12",
    "Gebaeudetyp_Auswahl_13",
    "Gebaeudetyp_Auswahl_14",
    "Gebaeudetyp_Auswahl_15",
    "Gebaeudetyp_Auswahl_16",
    "Gebaeudetyp_Auswahl_17",
    "Gebaeudetyp_Auswahl_18",
  ];

  const appendInput = useCallback((index) => {
    const newInput = `input-${index}`;
    setOptionalInputFieldsState((prevState) => ({
      inputs: prevState.inputs.concat([newInput]),
    }));
  }, []);

  const onDiagramClick = (index) => {
    localStorage.setItem("projectId", `${index}`);
    setDetailsInformation(userProjects[index].detailsInformation);
    setProjectId(index);
    setIsReady(true);
  };

  const rearrangeObjectKeys = (index) => {
    const size = Object.keys(userProjects).length;
    for (let i = index; i < size; i += 1) {
      userProjects[i] = userProjects[i + 1];
      delete userProjects[i + 1];
    }
  };

  const deleteElement = (index) => {
    const maxIndex = +Object.keys(userProjects).reduce((a, b) =>
      Math.max(a, b)
    );
    delete userProjects[index];
    rearrangeObjectKeys(index, maxIndex);
    if (isObjectEmpty(userProjects)) {
      localStorage.removeItem("userProjects");
      setUserProjects(null);
    } else setUserProjects({ ...userProjects });
  };

  const handleChangeProjectName = (event) => {
    setHeaderText(event.target.value);
  };

  const handleChangeEditor = (event) => {
    const { value } = event.target;
    setEditors(value);
    setIsModified(true);
  };

  const handleChangeVersion = (event) => {
    const { value } = event.target;
    setVersion(value);
    setIsModified(true);
  };

  const handleChangeDescription = (event) => {
    const { value } = event.target;
    setDescription(value);
    setIsModified(true);
  };

  const handleCloseSaveDialog = (status, filename) => {
    if (status === SUCCESS) {
      downloadFile(data, filename);
      setIsSuccessfullyExported(true);
    } else {
      setIsSuccessfullyExported(false);
    }
    setOpenSaveDialog(false);
  };

  const handleChangeTypeSelect = (event) => {
    setBuildingType(event.target.value);
    setIsModified(true);
  };

  const handleChangeSimpleSelect = (value) => {
    setAmountFloors(value);
    setIsModified(true);
  };

  const handleChangeMultipleFields = (fields) => {
    setMultipleSpecialFields(fields);
    console.log(fields);
    setIsModified(true);
  };

  const handleOnSave = () => {
    data.projectName = headerText;
    data.editors = editors;
    data.version = version;
    data.projectDescription = description;
    data.details = {
      buildingType,
      floors: amountFloors,
      specialFields: multipleSpecialFields,
    };
    setData({ ...data });
    setEnableExportProject(true);
    setCreatePDF(false);
    setFilename(data.projectName);
    setIsModified(false);

    localStorage.removeItem("pdfMode");
    localStorage.setItem("filename", data.projectName);
  };

  const deleteData = () => {
    setData(null);
    const lang = localStorage.getItem("i18nextLng");
    localStorage.clear();
    history.push("/project");
    localStorage.setItem("i18nextLng", lang);
    window.location.reload();
  };

  const handleChoiceQuestionDialog = (choice) => {
    if (choice === "no") {
      deleteData();
    } else setOpenSaveDialog(true);
    setOpenSaveQuestionDialog(false);
  };

  const handleChoiceContinueDialog = (choice) => {
    if (choice === "yes") {
      deleteData();
    }
    setIsSuccessfullyExported(false);
    setIsSuccessfullyExported(false);
  };

  const onDelete = (index) => {
    if (optionalInputFieldsState.inputs.length === 1) {
      optionalInputFieldsState.inputs = [];
    } else {
      optionalInputFieldsState.inputs = optionalInputFieldsState.inputs.filter(
        (el) => el !== `input-${index}`
      );
    }
    setOptionalInputFieldsState({ ...optionalInputFieldsState });
    setIsModified(true);
  };

  useEffect(() => {
    if (data) {
      const isTriggered = localStorage.getItem("langTriggered") === "true";
      if (isTriggered) {
        initLangPack(data.projects);
        if (data.userProjects) initLangPack(Object.values(data.userProjects));
      }
    }
  }, [data, initLangPack]);

  useEffect(() => {
    if (
      renderOnlyOnce &&
      isNewProject &&
      userProjects &&
      Object.entries(userProjects).length > 0
    ) {
      setOpenSaveQuestionDialog(true);
    }
  }, [isNewProject, renderOnlyOnce, userProjects]);

  return (
    <>
      <Header isChart />

      {data ? (
        <div>
          <div
            style={{ display: "flex", justifyContent: "center", margin: 20 }}
          >
            <ContentEditable
              style={{ fontSize: 30 }}
              innerRef={contentEditable}
              html={headerText}
              disabled={false}
              onChange={handleChangeProjectName}
              tagName="article"
            />
          </div>

          <div className={classes.root}>
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={8}>
                <Grid container spacing={8}>
                  <Grid item xs={3}>
                    <Paper className={classes.paperHeader}>
                      {t("Diagramme")}
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper className={classes.paperHeader}>
                      {t("Projektbereich")}
                    </Paper>
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
                <div style={{ margin: 15 }} />
                <Grid container spacing={8}>
                  <Grid item xs={3} className={classes.grid}>
                    {userProjects &&
                      Object.values(userProjects).map((project, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                          key={`diagram_${index}`}
                        >
                          <img
                            src={DiagramIcon}
                            style={{
                              maxWidth: 50,
                              marginRight: 10,
                            }}
                            onClick={() => onDiagramClick(index)}
                            alt="diagramIcon"
                            className="hoverDiv"
                          />
                          <div
                            onClick={() => onDiagramClick(index)}
                            className="hoverDiv"
                          >
                            {project.title}
                          </div>
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteElement(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ))}

                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setShowDiagramTypeDialog(true)}
                    >
                      + {t("Diagramm_text")}
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => history.push("/openProject")}
                    >
                      + {t("Open_Project")}
                    </Button>

                    <div className={classes.gridBottom}>
                      <Tooltip
                        arrow
                        title={t("Unsaved_Changes")}
                        placement="left"
                        open={isModified}
                      >
                        <Button
                          style={{ marginBottom: 20 }}
                          variant="contained"
                          color="primary"
                          onClick={handleOnSave}
                        >
                          {t("Speichern")}
                        </Button>
                      </Tooltip>

                      <DisabledButtonWithTooltip
                        variant="contained"
                        color="primary"
                        tooltipText={t("ToolTip_Pdf_Button_Disabled")}
                        onClick={() => {
                          localStorage.removeItem("pdfMode");
                          setCreatePDF(true);
                        }}
                        disabled={
                          !(
                            areAllDataUrlsValid &&
                            data &&
                            userProjects &&
                            Object.entries(userProjects).length > 0
                          )
                        }
                      >
                        {t("PDF_exportieren")}
                      </DisabledButtonWithTooltip>

                      {createPDF && (
                        <Button>
                          <PDFRenderer
                            fileName={filename}
                            closeCreator={() => setCreatePDF(false)}
                          />
                        </Button>
                      )}

                      <DisabledButtonWithTooltip
                        variant="contained"
                        color="primary"
                        tooltipText={t("ToolTip_Export_Button_Disabled")}
                        onClick={() => setOpenSaveDialog(true)}
                        disabled={!enableExportProject}
                        style={{ marginTop: 10 }}
                      >
                        {t("Exportieren")}
                      </DisabledButtonWithTooltip>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "transparent",
                      }}
                    >
                      <button
                        type="button"
                        className={clsx(classes.iconButton)}
                      >
                        <img src={iconBAP} alt="shows button" width="100%" />
                        {t("header1")}
                      </button>

                      <button
                        type="button"
                        className={clsx(classes.iconButton)}
                      >
                        <img src={iconLW} alt="shows button" width="100%" />
                        {t("header2")}
                      </button>
                      <button
                        type="button"
                        className={clsx(classes.iconButton)}
                      >
                        <img src={iconEL} alt="shows button" width="100%" />
                        {t("header3")}
                      </button>

                      <button
                        type="button"
                        className={clsx(classes.iconButton)}
                      >
                        <img src={iconBL} alt="shows button" width="100%" />
                        {t("header4")}
                      </button>
                    </Paper>
                    <Paper
                      className={classes.paperHeader}
                      style={{ marginTop: 10 }}
                    >
                      {t("Projektdetails")}
                    </Paper>
                    <form
                      className={classes.textField}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <TextField
                          id="textfield-editor"
                          label="Editor"
                          value={editors}
                          style={{ marginRight: 30 }}
                          onChange={handleChangeEditor}
                        />
                        <TextField
                          id="textfield-version"
                          label="Version"
                          value={version}
                          onChange={handleChangeVersion}
                        />
                      </div>
                    </form>
                    <TextField
                      className={classes.textArea}
                      style={{ width: "100%", fontSize: 10, color: "red" }}
                      id="outlined-multiline-static"
                      label={t("Projektbeschreibung")}
                      multiline
                      rows={4}
                      variant="outlined"
                      value={description}
                      onChange={handleChangeDescription}
                    />
                    <div className={classes.select}>
                      <TypeSelect
                        fields={buildingTypes}
                        helperText="Gebaedetyp_auswaehlen"
                        labelText={t("Gebaeudetyp_text")}
                        onChange={handleChangeTypeSelect}
                        defaultValue={data.details.buildingType}
                      />
                      <SimpleSelect
                        numberOfItems={50}
                        onChange={handleChangeSimpleSelect}
                        defaultValue={data.details.floors}
                      />
                    </div>

                    <SpecialAnnotationsList
                      annotations={multipleSpecialFields}
                      setAnnotations={setMultipleSpecialFields}
                      onListChanged={setIsModified}
                    />
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </Grid>
            </Grid>
          </div>
          {openSaveDialog && (
            <SaveDialog fileName={filename} onClose={handleCloseSaveDialog} />
          )}
          {showDiagramTypeDialog && (
            <DiagramTypeDialog
              onClose={() => setShowDiagramTypeDialog(false)}
            />
          )}
          {openSaveQuestionDialog && (
            <QuestionDialog close={handleChoiceQuestionDialog} />
          )}
          {isSuccessfullyExported && (
            <ContinueDialog close={handleChoiceContinueDialog} />
          )}
        </div>
      ) : (
        <div>No Project found. Please go back and upload a project.</div>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paperHeader: {
    padding: theme.spacing(2),
    color: "white",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
  },
  paperContent: {
    padding: theme.spacing(2),
    color: "white",
    backgroundColor: "#f1f6fa",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  },
  header: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: 20,
    marginBottom: 20,
    textTransform: "uppercase",
  },
  drawerPaper: {
    backgroundColor: "blue",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
  },
  iconButton: {
    backgroundColor: "beige",
    border: "none",
    fontSize: 10,
    color: theme.palette.primary.main,
    boxShadow: "0px 0px 6px #00000029",
    borderRadius: 2,
    width: 120,
  },
  textField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
    display: "flex",
    justifyContent: "left",
  },
  textArea: {
    borderRadius: 12,
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
      display: "inline-block",
    },
  },
  grid: {
    backgroundColor: "#f1f6fa",
    borderRadius: 12,
  },
  gridBottom: {
    backgroundColor: "#f1f6fa",
    borderRadius: 12,
    justifyContent: "flex-end",
    flexDirection: "column",
    display: "flex",
    marginTop: 50,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    display: "flex",
    justifyContent: "left",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
}));

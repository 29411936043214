import React, { useEffect, useState } from "react";
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import LZString from "lz-string";
import * as d3 from "d3";
import { useData } from "../../DataProvider";
import { isObjectEmpty, localStorageHasItem } from "../../../helpers/util";
import Logo from "../../../images/Hofstadler_Logo.png";
import Watermark from "../../../images/Wasserzeichen.png";

const BORDER_COLOR = "#FFFFFF";
const BORDER_STYLE = "solid";
const COL2_WIDTH = 30;
const COL3_WIDTH = 70;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingLeft: 50,
    paddingRight: 20,
  },
  image: {
    width: "100%",
    padding: 10,
  },
  overallImage: {
    width: "200%",
    padding: 10,
  },
  centerImage: {
    alignItems: "center",
    flexGrow: 1,
  },
  centerOverallImage: {
    alignItems: "center",
    flexGrow: 1,
    transform: "rotate(270deg)",
    marginTop: 200,
    border: "1px solid black",
  },
  paddingHeader: {
    paddingHorizontal: 70,
    paddingVertical: 50,
  },
  text: {
    width: "100%",
    color: "#0064A7",
    marginTop: 30,
    fontSize: 13,
  },
  axisText: {
    color: "#0064A7",
    fontSize: 13,
    marginBottom: 10,
    marginLeft: 50,
  },
  headerText: {
    width: "100%",
    color: "#0c3d5e",
    marginTop: 50,
    marginBottom: 20,
    fontSize: 16,
  },
  viewText: {
    width: "100%",
    textAlign: "right",
    fontSize: 10,
    marginTop: 10,
    color: "#0c3d5e",
  },
  header: {
    color: "rgba(15,72,150,0.99)",
    margin: 20,
  },
  body: {
    padding: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColMiddle: {
    width: `${COL2_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColRight: {
    width: `${COL3_WIDTH}%`,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    color: "#17295a",
    fontSize: 13,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  tableCell1WorkingLines: {
    backgroundColor: "#3256dc",
    border: "0px solid transparent",
    color: "white",
  },
  tableCell2WorkingLines: {
    backgroundColor: "#898eb6",
    border: "0px solid transparent",
    color: "white",
  },
  watermark: {
    zIndex: 100,
    position: "absolute",
    width: "60%",
    height: "60%",
    top: "30%",
    left: "25%",
  },
  emptyView: {
    width: "100%",
    height: 200,
  },
});

const convertToRomanNumbers = (number) => {
  switch (number) {
    case 1:
      return "I";
    case 2:
      return "II";
    case 3:
      return "III";
    case 4:
      return "IV";
    default:
      return "none";
  }
};

const WatermarkDrawer = ({ isStudentVersion }) =>
  isStudentVersion ? <Image src={Watermark} style={styles.watermark} /> : null;

const PartialImage = ({ dataUrls, userProject, isStudentVersion }) =>
  dataUrls.map((dataUrl, index) => (
    <View break key={dataUrl}>
      <View>
        <View style={styles.centerImage}>
          <Text style={styles.header}>
            {`Diagramm für Quadrant ${convertToRomanNumbers(index + 1)}`}
          </Text>
          <Image style={styles.image} src={dataUrl} />
        </View>
        <View>
          <Text style={styles.axisText}>
            Y-Achse: {userProject?.quadrants[index]?.y.yAxisLabel}
          </Text>
          <Text style={styles.axisText}>
            X-Achse: {userProject?.quadrants[index]?.x.xAxisLabel}
          </Text>
        </View>
        <View>
          <Text style={styles.headerText}>
            Ausgabewerte für Arbeitslinie(n)
          </Text>
        </View>
        <WorkingLinesTable
          workingLines={userProject?.quadrants[index]?.workingLines}
        />
        <View style={styles.emptyView} />
      </View>
      <WatermarkDrawer isStudentVersion={isStudentVersion} />
    </View>
  ));

const OverallPicture = ({ imageSrc, isStudentVersion, project }) => {
  return (
    <View break>
      <View style={styles.centerOverallImage}>
        <Text style={{ marginBottom: 8 }}>Interaktionsdiagramm</Text>
        {/* {project.title && (
          <Text style={{ marginBottom: 30, fontSize: 15 }}>
            {project.title}
          </Text>
        )} */}
        <Image style={styles.overallImage} src={imageSrc} />
      </View>
      <WatermarkDrawer isStudentVersion={isStudentVersion} />
    </View>
  );
};

const SpecialFields = ({ data, t }) => {
  if (Array.isArray(data.details.specialFields) === true) {
    return (
      <>
        {data.details.specialFields.map((field, index) => {
          return (
            <View style={styles.tableRow} key={`special_fields_${field.id}`}>
              <View style={styles.tableColMiddle}>
                <Text style={styles.tableCell}>{t(field.selected)}: </Text>
              </View>
              <View style={styles.tableColRight}>
                <Text style={styles.tableCell}>{field.amount}</Text>
              </View>
            </View>
          );
        })}
      </>
    );
  }
};

const WorkingLinesTable = ({ workingLines }) => {
  if (!workingLines || isObjectEmpty(workingLines)) return <View />;
  return (
    <Table data={workingLines}>
      <TableHeader textAlign="center" fontSize={14}>
        <TableCell style={styles.tableCell1WorkingLines} />
        <TableCell style={styles.tableCell2WorkingLines}>X-Wert</TableCell>
        <TableCell style={styles.tableCell1WorkingLines}>Y-Wert</TableCell>
        <TableCell style={styles.tableCell2WorkingLines}>Endwert</TableCell>
      </TableHeader>
      <TableBody textAlign="center">
        <DataTableCell
          style={styles.tableCell1WorkingLines}
          getContent={(line) => line.name}
        />
        <DataTableCell
          style={styles.tableCell2WorkingLines}
          getContent={(line) => line.valueXAxis.toFixed(line.decimalPlaces)}
        />
        <DataTableCell
          style={styles.tableCell1WorkingLines}
          getContent={(line) => line.valueYAxis.toFixed(line.decimalPlaces)}
        />
        <DataTableCell
          style={styles.tableCell2WorkingLines}
          getContent={(line) => line.valueZ.toFixed(line.decimalPlaces)}
        />
      </TableBody>
    </Table>
  );
};

const CompletePDFDocument = ({
  data,
  t,
  userProjects,
  hasSpecialFields,
  isStudentVersion,
  pngs,
}) => (
  <Document>
    <Page style={styles.page} size="A4">
      {/* <Text render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed /> */}
      <View>
        <Image src={Logo} />
      </View>

      <View style={{ paddingTop: 20 }} />
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.tableColMiddle}>
            <Text style={styles.tableCell}>Projekt:</Text>
          </View>
          <View style={styles.tableColRight}>
            <Text style={styles.tableCell}>{data.projectName}</Text>
          </View>
        </View>

        {Object.values(userProjects).map((project, index) => (
          <View style={styles.tableRow} key={project.title}>
            <View style={styles.tableColMiddle}>
              <Text style={styles.tableCell}>
                {index === 0 ? "Diagrammtitel :" : ""}{" "}
              </Text>
            </View>
            <View style={styles.tableColRight}>
              <Text style={styles.tableCell}>{project.title}</Text>
            </View>
          </View>
        ))}

        <View style={styles.tableRow}>
          <View style={styles.tableColMiddle}>
            <Text style={styles.tableCell}>Inhalt:</Text>
          </View>
          <View style={styles.tableColRight}>
            <Text style={styles.tableCell}>{data.projectDescription}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColMiddle}>
            <Text style={styles.tableCell}>BearbeiterIn:</Text>
          </View>
          <View style={styles.tableColRight}>
            <Text style={styles.tableCell}>{data.editors}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColMiddle}>
            <Text style={styles.tableCell}>Datum</Text>
          </View>
          <View style={styles.tableColRight}>
            <Text style={styles.tableCell}>{data.date}</Text>
          </View>
        </View>
        <View style={styles.tableRow}>
          <View style={styles.tableColMiddle}>
            <Text style={styles.tableCell}>Version:</Text>
          </View>
          <View style={styles.tableColRight}>
            <Text style={styles.tableCell}>{data.version}</Text>
          </View>
        </View>

        {data.details && data.details.buildingType !== "" && (
          <View style={styles.tableRow}>
            <View style={styles.tableColMiddle}>
              <Text style={styles.tableCell}>
                {t(data.details.buildingType)}:
              </Text>
            </View>
            <View style={styles.tableColRight}>
              <Text style={styles.tableCell}>
                Stockwerke:
                {` ${data.details.floors}`}
              </Text>
            </View>
          </View>
        )}

        {hasSpecialFields && <SpecialFields data={data} t={t} />}
      </View>

      {Object.values(userProjects).map((project, index) => {
        const urls = pngs[index];

        return (
          <View>
            <OverallPicture
              imageSrc={urls[0]}
              project={project}
              isStudentVersion={isStudentVersion}
            />
            <PartialImage
              dataUrls={urls.slice(1)}
              userProject={project}
              isStudentVersion={isStudentVersion}
            />
          </View>
        );
      })}
    </Page>
  </Document>
);

const SimplePDFDocument = (data) => {
  return (
    <Document>
      <Page style={styles.page} size="A4">
        <View>
          <WatermarkDrawer isStudentVersion={!data.isStudentVersion} />
          <OverallPicture
            imageSrc={data.image}
            isStudentVersion={!data.isStudentVersion}
            project={data.project}
          />
        </View>
      </Page>
    </Document>
  );
};

export default function PDFRenderer({
  fileName,
  exportSinglePagePDF,
  singlePdfResolutionWidth,
}) {
  const { data, userProjects, isStudentVersion, projectId } = useData();
  const [pngs, setPngs] = useState([]);
  const { t } = useTranslation();
  const hasSpecialFields = !isObjectEmpty(data.details.specialFields);
  data.date = new Date().toDateString();
  const onlySimplePage = localStorageHasItem("pdfMode");
  const currentProject = userProjects[projectId];

  useEffect(() => {
    const decompress = (dataStr) => LZString.decompress(dataStr);

    const svgUrlToPng = (svgUrl, index) =>
      new Promise((resolve, eject) => {
        try {
          const image = d3.select("body").append("img").node();
          image.onload = () => {
            const canvas = document.createElement("CANVAS");
            canvas.width = image.offsetWidth;
            canvas.height = image.offsetHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, image.offsetWidth, image.offsetHeight);
            const canvasUrl = canvas.toDataURL("image/png");
            image.remove();
            resolve(canvasUrl);
          };
          image.src = svgUrl;
          if (index === 0) {
            image.width = 3200;
          } else {
            image.width = 2000;
          }
        } catch (e) {
          eject(e);
        }
      });

    const svgStringToPng = async (svgStr, index) => {
      const blob = new Blob([svgStr], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);

      const pngDataUrl = await svgUrlToPng(url, index);
      URL.revokeObjectURL(url);
      return pngDataUrl;
    };

    const convert = async (project) =>
      Promise.all(
        project.dataUrls.map(async (compressedSvgString, index) => {
          const decompressSvgString = decompress(compressedSvgString);
          return svgStringToPng(decompressSvgString, index);
        })
      );

    const convertAllSvgsToPngs = async () =>
      Promise.all(
        Object.values(userProjects).map(async (userProject) =>
          convert(userProject)
        )
      );

    const getAll = async () => {
      const nestedArray = await convertAllSvgsToPngs();
      setPngs(nestedArray);
    };

    getAll();
  }, [projectId, userProjects]);

  if (pngs.length === 0) return null;

  if (onlySimplePage || exportSinglePagePDF) {
    return (
      <PDFDownloadLink
        style={{ color: "inherit", textDecoration: "none" }}
        document={
          <SimplePDFDocument
            data={data}
            hasSpecialFields={hasSpecialFields}
            isStudentVersion={isStudentVersion}
            project={currentProject}
            image={pngs[projectId][0]}
          />
        }
        fileName={fileName}
      >
        {({ loading }) => (loading ? t("Dokument_laden") : t("PDF"))}
      </PDFDownloadLink>
    );
  }

  return (
    <PDFDownloadLink
      fileName={fileName}
      document={
        <CompletePDFDocument
          data={data}
          t={t}
          userProjects={userProjects}
          hasSpecialFields={hasSpecialFields}
          isStudentVersion={isStudentVersion}
          pngs={pngs}
        />
      }
    >
      {({ loading }) =>
        loading ? t("Dokument_laden") : t("Dokument_downloaden")
      }
    </PDFDownloadLink>
  );
}

PDFRenderer.propTypes = {
  fileName: PropTypes.string.isRequired,
};

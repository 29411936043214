import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

import { PropTypes } from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  textField: {
    display: "flex",
    position: (props) => (props.isAbsolute ? "absolute" : null),
    marginTop: (props) => (props.isAbsolute ? -18 : 0),
    justifyContent: "flex-end",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 30,
    width: (props) => props.width,
    color: "red",
    fontSize: 30,
    opacity: 1,
    borderBottom: 0,
    "&:before": {
      borderBottom: 0,
    },
  },

  btnIcons: {
    marginLeft: 10,
  },
}));

function EditableTextField({
  value,
  isCentered,
  onChange,
  width,
  maxLength,
  isAbsolute,
  style,
  id,
  isMultiline,
  fontSize,
  onMouseLeave,
}) {
  const [inputValue, setInputValue] = useState(value);

  const classes = useStyles({ width, isAbsolute });

  const handleChange = (event) => {
    onChange(event.target.value);
    setInputValue(event.target.value);
  };

  const handleKeyboardChange = (event) => {
    if (event.keyCode === 13) {
      setInputValue(inputValue.slice(0, -1));
    }
  };

  return (
    <TextField
      id={id}
      style={style}
      defaultValue={value}
      multiline={isMultiline || false}
      error={inputValue === ""}
      onMouseLeave={onMouseLeave}
      onChange={handleChange}
      onKeyUp={handleKeyboardChange}
      className={classes.textField}
      inputProps={{
        style: isCentered
          ? {
              textAlign: "center",
              fontSize: fontSize || 16,
              fontWeight: "bold",
            }
          : null,
        maxLength,
      }}
    />
  );
}

EditableTextField.propTypes = {
  value: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  maxLength: PropTypes.number,
  isAbsolute: PropTypes.bool,
};

EditableTextField.defaultProps = {
  width: 300,
  maxLength: 100,
  isAbsolute: false,
  isCentered: false,
};

export default EditableTextField;

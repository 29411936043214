import React, { useEffect, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import PropTypes from "prop-types";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  applyDrag,
  generateItems,
  getClosedWorkingLines,
} from "../../../helpers/util";
import { useData } from "../../DataProvider";
import { triggerVisibility } from "../../subjects/d3Subjects";
import Quadrant from "../chartFunctions/Quadrant";

const onDragStyles = {
  transition: "all .18s ease",
  opacity: 0.8,
  cursor: "ns-resize",
  backgroundColor: "cornflowerblue",
  boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.3)",
};

const onSelectedStyle = {
  backgroundColor: "#ffdb3a",
  width: "100%",
};

const onStandardStyle = {
  backgroundColor: "#e1e1e1",
  width: "100%",
};

const onCombinedWorkingLineStyle = {
  backgroundColor: "#750101",
  color: "white",
  width: "100%",
};

export default function PlaneItems({ id, showAllElements, planeItems }) {
  const {
    setPlaneItems,
    selectedPlaneItem,
    setSelectedPlaneItem,
    setSelectedPlane,
    topPlane,
    projectId,
    userProjects,
  } = useData();
  const [onDrag, setOnDrag] = useState(false);
  const { t, i18n } = useTranslation();
  const key = id - 1;
  const localPlaneItems = [...planeItems];
  const isDiagramTypeT3 = userProjects[projectId].typeNumber === "3";

  const handleVisible = (planeId, showElement) => {
    if (showElement !== undefined) {
      const planeItem = planeItems[key].find((el) => el.id === planeId);
      planeItem.isVisible = showElement;
      triggerVisibility({
        id: planeId,
        isVisible: showElement,
      });
    } else {
      const planeItem = planeItems[key].find((el) => el.id === planeId);
      const { isVisible } = planeItem;
      planeItem.isVisible = !isVisible;
      triggerVisibility({
        id: planeId,
        isVisible: !isVisible,
      });
    }
    setPlaneItems([...planeItems]);
  };

  const handleChecked = (planeId) => {
    const planeItem = planeItems[key].find((el) => el.id === planeId);
    const { isChecked } = planeItem;
    planeItem.isChecked = !isChecked;
    setPlaneItems([...planeItems]);
  };

  useEffect(() => {
    const initPlaneItems = (lines) => {
      const tmpCreator = (i) => {
        const { weightFactors } = userProjects[projectId].combinedWorkingLines;
        const array = weightFactors.find((el) => el.id === lines[i].instanceId);

        return {
          id: lines[i].instanceId,
          data: lines[i].name,
          isCombined: lines[i].isCombined,
          isVisible: true,
          isChecked: true,
          weightFactor: array !== undefined ? array.weightFactor : 1,
        };
      };

      localPlaneItems[key] = generateItems(lines.length, tmpCreator);
      const newPlaneItems = [...localPlaneItems];
      setPlaneItems(newPlaneItems);
    };

    const addPlaneItem = (line) => {
      const newPlaneObject = {
        id: line.instanceId,
        data: line.name,
        isCombined: line.isCombined,
        isVisible: true,
        isChecked: true,
        weightFactor: 1,
      };
      if (!localPlaneItems[key]) {
        for (let i = 0; i < key; i += 1) {
          localPlaneItems.push([]);
        }
      }
      localPlaneItems[key].push(newPlaneObject);
    };

    const findItemById = (planeId) => {
      for (
        let planeIndex = 0;
        planeIndex < localPlaneItems.length;
        planeIndex += 1
      ) {
        const planeItemIndex = localPlaneItems[planeIndex].findIndex(
          (item) => item.id === planeId
        );
        if (planeItemIndex !== -1) {
          return {
            planeIndex,
            planeItemIndex,
          };
        }
      }
      return {
        planeIndex: -1,
        planeItemIndex: -1,
      };
    };

    const closedLines = getClosedWorkingLines(
      Quadrant.firstQuadrant.workingLines
    );
    if (key === topPlane) {
      if (localPlaneItems.length === 0) {
        initPlaneItems(closedLines);
      } else {
        closedLines.forEach((line) => {
          const { planeIndex, planeItemIndex } = findItemById(line.instanceId);
          if (planeItemIndex === -1) {
            addPlaneItem(line);
          } else {
            localPlaneItems[planeIndex][planeItemIndex].data = line.name;
          }
        });
      }
    }
  }, [key, localPlaneItems, setPlaneItems, topPlane]);

  useEffect(() => {
    if (
      showAllElements !== undefined &&
      planeItems.length > 0 &&
      typeof planeItems[key] !== "undefined"
    ) {
      planeItems[key].forEach((item) => {
        handleVisible(item.id, showAllElements);
      });
    }
  }, [showAllElements]);

  const dragStyle = (dragId, isCombinedWorkingLine) => {
    if (isCombinedWorkingLine) return onCombinedWorkingLineStyle;
    const isSelected = selectedPlaneItem === dragId;
    if (onDrag) {
      return isSelected
        ? { ...onDragStyles, ...onSelectedStyle }
        : onDragStyles;
    }
    return isSelected
      ? { ...onStandardStyle, ...onSelectedStyle }
      : onStandardStyle;
  };

  const updateSelectedItems = (planeId) => {
    setSelectedPlaneItem(planeId);
    setSelectedPlane(undefined);
  };

  const handleOnChange = (value, index) => {
    const inputValue = value.replace(",", ".");
    const newPlaneItems = [...localPlaneItems];
    if (newPlaneItems[key][index]) {
      newPlaneItems[key][index].weightFactor = inputValue;
    }
    setPlaneItems([...newPlaneItems]);
    localStorage.setItem("planeItems", JSON.stringify(newPlaneItems));
  };

  const formatString = (value) => {
    if (i18n.language === "de") {
      return value.toString().replace(".", ",");
    }

    return value.toString().replace(",", ".");
  };

  return (
    <div style={{ width: "100%" }}>
      <Container
        dragClass="opacity-ghost"
        dropClass="opacity-ghost-drop"
        groupName="1"
        getChildPayload={(i) => localPlaneItems[key][i]}
        onDragStart={() => {
          setOnDrag(true);
        }}
        onDrop={(e) => {
          localPlaneItems[key] = localPlaneItems[key]
            ? localPlaneItems[key]
            : "";
          localPlaneItems[key] = applyDrag(localPlaneItems[key], e);
          const newPlaneItems = [...localPlaneItems];
          setPlaneItems(newPlaneItems);
        }}
        onDragEnd={() => {
          setOnDrag(false);
        }}
      >
        {localPlaneItems &&
          localPlaneItems[key] &&
          localPlaneItems[key].map((planeItem, index) => (
            <Draggable
              key={planeItem.id}
              onClick={() => updateSelectedItems(planeItem.id)}
              style={{ flexGrow: 2 }}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  style={{ flexGrow: 0 }}
                  color="primary"
                  aria-label="show visibility"
                  component="span"
                  onClick={() => handleVisible(planeItem.id)}
                >
                  {planeItems[key].find((el) => el.id === planeItem.id)
                    .isVisible ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
                <FormControlLabel
                  label=""
                  control={
                    <Checkbox
                      checked={
                        planeItems[key].find((el) => el.id === planeItem.id)
                          .isChecked
                      }
                      onChange={() => handleChecked(planeItem.id)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                />
                <div
                  className="draggable-item"
                  style={dragStyle(planeItem.id, planeItem.isCombined)}
                >
                  {planeItem.data}
                </div>

                {isDiagramTypeT3 && (
                  <TextField
                    style={{ marginTop: 10, marginLeft: 5, width: 300 }}
                    id="outlined-basic"
                    label={t("weightFactor")}
                    variant="outlined"
                    size="small"
                    type="text"
                    inputProps={{ min: 0 }}
                    value={formatString(planeItem.weightFactor)}
                    onChange={(event) =>
                      handleOnChange(event.target.value, index)
                    }
                  />
                )}
              </div>
            </Draggable>
          ))}
      </Container>
    </div>
  );
}

PlaneItems.propTypes = {
  id: PropTypes.number.isRequired,
  showAllElements: PropTypes.bool.isRequired,
};

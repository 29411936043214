import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useData } from "../../DataProvider";
import {
  readObjectFromLocalStorage,
  storeObjectInLocalStorage,
} from "../../../helpers/util";

const DetailsDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(true);
  const {
    setUserProjects,
    projectId,
    detailsInformation,
    setDetailsInformation,
  } = useData();

  const useStyles = makeStyles({
    dialog: {
      position: "absolute",
      left: ref.current.getBoundingClientRect().width,
      top: ref.current.getBoundingClientRect().height + 35,
      width: 265,
      paddingBottom: 20,
    },
  });
  const classes = useStyles();
  const { close } = props;
  const { t } = useTranslation();

  const handleClose = () => {
    const userProjects = readObjectFromLocalStorage("userProjects");
    userProjects[projectId].detailsInformation = detailsInformation;
    storeObjectInLocalStorage("userProjects", userProjects);
    setUserProjects(userProjects);
    setOpen(false);
    close();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editor_text_field"
            label="Editor"
            placeholder={t("Placeholder_Editor_hinzufuegen")}
            type="text"
            fullWidth
            value={detailsInformation.editor}
            onChange={(event) => {
              const newDetailsInformation = {
                ...detailsInformation,
                editor: event.target.value,
              };
              setDetailsInformation(newDetailsInformation);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="version_text_field"
            label="Version"
            placeholder={t("Placeholder_Version_hinzufuegen")}
            type="text"
            fullWidth
            value={detailsInformation.version}
            onChange={(event) => {
              const newDetailsInformation = {
                ...detailsInformation,
                version: event.target.value,
              };
              setDetailsInformation(newDetailsInformation);
            }}
          />
          <TextField
            style={{ marginTop: 20 }}
            id="outlined-multiline-static"
            label={t("Diagrammbeschreibung")}
            multiline
            rows={4}
            variant="outlined"
            placeholder={t("Placeholder_Diagrammbeschreibung_hinzufuegen")}
            value={detailsInformation.desc}
            onChange={(event) => {
              const newDetailsInformation = {
                ...detailsInformation,
                desc: event.target.value,
              };
              setDetailsInformation(newDetailsInformation);
            }}
          />
          <TextField
            style={{ marginTop: 20, width: "100%" }}
            label={t("fontSize")}
            placeholder={t("fontSize")}
            type="number"
            InputProps={{ inputProps: { min: 10, max: 25 } }}
            defaultValue={
              detailsInformation.fontSize ? detailsInformation.fontSize : "12"
            }
            onChange={(event) => {
              if (Number.isNaN(event.target.value)) {
                return;
              }
              const newDetailsInformation = {
                ...detailsInformation,
                fontSize: event.target.value,
              };
              setDetailsInformation(newDetailsInformation);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});

DetailsDialog.propTypes = {
  close: PropTypes.func.isRequired,
};

export default DetailsDialog;

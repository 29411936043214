import { Subject } from "rxjs";

const additionalWorkingLineCalculationTriggerSubject$ = new Subject();
const extendWorkingLineHorizontal$ = new Subject();
const extendWorkingLineVertical$ = new Subject();
const alignWorkingLines$ = new Subject();
const openToolbarSubject$ = new Subject();
const showCombinedLineDisabledToastSubject$ = new Subject();
const resetZoomAll$ = new Subject();
const resetZoom$ = new Subject();
const updateZoom$ = new Subject();
const deleteAllWorkingLines$ = new Subject();
const visibility$ = new Subject();
const yLabelText$ = new Subject();
const xLabelText$ = new Subject();

export function triggerAdditionalWorkingLineCalculation(val) {
  additionalWorkingLineCalculationTriggerSubject$.next(val);
}

export function notifyExtendWorkingLineHorizontal(val) {
  extendWorkingLineHorizontal$.next(val);
}

export function notifyExtendWorkingLineVertical(val) {
  extendWorkingLineVertical$.next(val);
}

export function notifyOtherWorkingLines(val) {
  alignWorkingLines$.next(val);
}

export function openToolbar(obj) {
  openToolbarSubject$.next(obj);
}

export function showCombinedLineDisabledToast() {
  showCombinedLineDisabledToastSubject$.next();
}

export function resetZoomTriggerAll() {
  resetZoomAll$.next();
}

export function resetZoomTrigger(val) {
  resetZoom$.next(val);
}

export function updateZoomTrigger(val) {
  updateZoom$.next(val);
}

export function deleteAllWorkingLines(val) {
  deleteAllWorkingLines$.next(val);
}

export function triggerVisibility(val) {
  visibility$.next(val);
}

export function updateYAxisLabelText(val) {
  yLabelText$.next(val);
}

export function updateXAxisLabelText(val) {
  xLabelText$.next(val);
}

export function getWorkingLineCalculationSubject() {
  return additionalWorkingLineCalculationTriggerSubject$;
}

export function getExtendWorkingLineHorizontalSubject() {
  return extendWorkingLineHorizontal$;
}

export function getExtendWorkingLineVerticalSubject() {
  return extendWorkingLineVertical$;
}

export function getWorkingLineAlignListener() {
  return alignWorkingLines$;
}

export function openToolbarListener() {
  return openToolbarSubject$;
}

export function showCombinedLineDisabledToastListener() {
  return showCombinedLineDisabledToastSubject$;
}

export function getResetZoomAllListener() {
  return resetZoomAll$;
}

export function getResetZoomListener() {
  return resetZoom$;
}

export function getUpdateZoomListener() {
  return updateZoom$;
}

export function getDeleteAllWorkingLinesListener() {
  return deleteAllWorkingLines$;
}

export function getVisibilityListener() {
  return visibility$;
}

export function getUpdateYAxesTextListener() {
  return yLabelText$;
}

export function getUpdateXAxesTextListener() {
  return xLabelText$;
}

import * as d3 from "d3";
import {
  findLowestNumberNotInList,
  formatNumber,
  nextUUID,
  roundDecimalPlaces,
  storeWorkingLinesInLocalStorage,
  lineFunction,
  rangeFunction,
} from "../../../helpers/util";
import {
  getVisibilityListener,
  notifyOtherWorkingLines,
  openToolbar,
  showCombinedLineDisabledToast
} from "../../subjects/d3Subjects";
import {
  getLabelStatus,
  getSnappingStatus,
  getLineSnappingStatus,
} from "../../subjects/toolbar_subjects";
import ArrowDirection from "./ArrowDirection";

export default class WorkingLine {
  static counterQ1 = 0;

  static counterQ2 = 0;

  static counterQ3 = 0;

  static counterQ4 = 0;

  static colorArray = [
    "#000000",
    "#ed1c24",
    "#058000",
    "#800080",
    "#009999",
    "#09FF00",
    "#009999",
    "#404040",
    "#07BF00",
    "#800080",
    "#808080",
    "#BF30BF",
  ];

  constructor(
    quadrant,
    posX,
    posY,
    valueX,
    valueY,
    labelX,
    labelY,
    noOffset = false,
    name,
    color,
    strokeWidth,
    strokeIndex,
    isCombined,
    arrowDirectionIndex,
    sequenceNumber,
    decimalPlaces,
    isVisible,
    showLabels,
    stickTheLabel
  ) {
    this.quadrant = quadrant;
    this.index = quadrant.index;
    this.x = quadrant.x;
    this.y = quadrant.y;
    this.width = quadrant.width;
    this.height = quadrant.height;
    this.isFirstQuadrant = quadrant.isFirstQuadrant;
    this.isSecondQuadrant = quadrant.isSecondQuadrant;
    this.isThirdQuadrant = quadrant.isThirdQuadrant;
    this.isFourthQuadrant = quadrant.isFourthQuadrant;
    this.isOnLeftSide = quadrant.isOnLeftSide;
    this.isOnBottomSide = quadrant.isOnBottomSide;
    this.svgRef = this.quadrant.svgRef;
    this.svgMargin = this.quadrant.svgMargin;
    this.xMin = quadrant.xMin;
    this.xMax = quadrant.xMax;
    this.yMin = quadrant.yMin;
    this.yMax = quadrant.yMax;
    this.formulaX = quadrant.formulaX;
    this.formulaY = quadrant.formulaY;
    this.formulaZ = quadrant.formulaZ;
    this.posX = posX !== undefined ? posX : -1;
    this.posY = posY !== undefined ? posY : -1;
    this.offsetX = posX;
    this.offsetY = posY;
    this.labelX = labelX;
    this.labelY = labelY;
    this.qNumber = quadrant.number;
    this.horizontalLine = null;
    this.verticalLine = null;
    this.horizontalText = null;
    this.arrowMarker = null;
    this.sequenceNumber =
      sequenceNumber !== undefined
        ? sequenceNumber
        : WorkingLine.nextSequenceNumber(quadrant);
    this.valueXAxis = valueX !== undefined ? valueX : -1;
    this.valueYAxis = valueY !== undefined ? valueY : -1;
    this.valueZ = 0;
    this.instanceId = `${this.qNumber}_${this.sequenceNumber}`;
    this.noOffset = noOffset;
    this.strokeWidth = strokeWidth !== undefined ? strokeWidth : 2;
    this.decimalPlaces = decimalPlaces !== undefined ? decimalPlaces : 2;
    this.strokeDashArray = WorkingLine.createStrokes();
    this.strokeIndex = strokeIndex !== undefined ? strokeIndex : 0;
    this.isVisible = isVisible !== undefined ? isVisible : true;
    this.name = "";
    this.color =
      color !== undefined
        ? color
        : WorkingLine.colorArray[
        this.sequenceNumber % WorkingLine.colorArray.length
        ];
    this.isSnapping = false;
    this.isLineSnapping = false;
    this.showLabels = showLabels ?? true;
    this.stickTheLabel = stickTheLabel ?? true;
    this.isCombined = isCombined !== undefined ? isCombined : false;
    this.putInLocalStorage = true;
    this.arrorMarkerLeft =
      this.isThirdQuadrant || this.isSecondQuadrant
        ? "marker-end"
        : "marker-start";
    this.arrorMarkerRight =
      this.isThirdQuadrant || this.isSecondQuadrant
        ? "marker-start"
        : "marker-end";
    this.arrorMarkerUp = "marker-end";
    this.arrorMarkerDown = "marker-start";
    this.currentArrorMarkerHorizontal = this.arrorMarkerLeft;
    this.currentArrorMarkerVertical = this.arrorMarkerDown;
    this.isHorizontalArrayVisible = false;
    this.isVerticalArrayVisible = false;
    this.arrowMarkerUUID = nextUUID();
    this.arrowDirectionIndex =
      arrowDirectionIndex !== undefined ? arrowDirectionIndex : 5;
    this.mousePositionTextSize = "12px";

    const cornerPointsOffset = this.calculateOffsetFromPixelValue(5, 5);
    this.fivePixelOffsetX = cornerPointsOffset.offsetX;
    this.fivePixelOffsetY = cornerPointsOffset.offsetY;

    WorkingLine.increaseCounter(this.index);

    getLabelStatus().subscribe((status) => {
      this.showLabels = status;
      this.updateWorkingLine(this.offsetX, this.offsetY);
    });

    getSnappingStatus().subscribe((isSnapping) => {
      this.isSnapping = isSnapping;
    });

    getLineSnappingStatus().subscribe((isLineSnapping) => {
      this.isLineSnapping = isLineSnapping;
    });

    getVisibilityListener().subscribe((state) => {
      if (Number(state.id.substring(2)) === this.sequenceNumber) {
        this.isVisible = state.isVisible ? 1 : 0;
        this.updateWorkingLine(this.offsetX, this.offsetY);
      }
    });

    this.setPosition();
    this.setAxisValues();
    this.createLines();
    this.initName(name);
    this.addWorkingLine();
  }

  createLines() {
    this.arrowMarker = this.addArrowMarker();
    this.horizontalLine = this.drawHorizontalLine(
      this.offsetX,
      this.offsetY,
      this.color
    );
    this.verticalLine = this.drawVerticalLine(
      this.offsetX,
      this.offsetY,
      this.color
    );
    this.horizontalText = this.drawTextToHorizontalLine(
      this.offsetX,
      this.offsetY,
      this.color
    );
    this.mousePositionText = this.drawMousePositionText(
      this.labelX,
      this.labelY,
      this.color
    );
    this.setArrowDirection(this.arrowDirectionIndex);
  }

  updatePositionCornerButtons(offsetX, offsetY) {
    if (this.cornerPointX)
      this.cornerPointX
        .style("opacity", this.isVisible)
        .attr("transform", `translate(${offsetX},${this.fivePixelOffsetY})`);

    if (this.cornerPointY)
      this.cornerPointY
        .style("opacity", this.isVisible)
        .attr("transform", `translate(${this.fivePixelOffsetX},${offsetY})`);
  }

  addWorkingLine() {
    this.updateFixedValues(this.offsetX, this.offsetY);

    this.centerPoint = this.quadrant.plot
      .append("circle")
      .attr("transform", `translate(${this.offsetX},${this.offsetY})`)
      .attr("id", `centerPointId_${this.instanceId}`)
      .attr("r", "5")
      .style("fill", this.color)
      .style("opacity", this.isVisible);

    this.cornerPointX = this.quadrant.plot
      .append("circle")
      .attr("transform", `translate(${this.offsetX},${this.fivePixelOffsetY})`)
      .attr("id", `cornerPointXId_${this.instanceId}`)
      .attr("r", "5")
      .attr("fill", this.color)
      .style("opacity", this.isVisible);

    this.cornerPointY = this.quadrant.plot
      .append("circle")
      .attr("transform", `translate(${this.fivePixelOffsetX},${this.offsetY})`)
      .attr("id", `cornerPointYId_${this.instanceId}`)
      .attr("r", "5")
      .attr("fill", this.color)
      .style("opacity", this.isVisible);

    if (!this.isCombined) {
      this.centerPoint.style("cursor", "pointer");
      this.cornerPointX.style("cursor", "col-resize");
      this.cornerPointY.style("cursor", "row-resize");
      this.mousePositionText.style("cursor", "pointer");
    } else {
      this.centerPoint.style("cursor", "not-allowed");
      this.cornerPointX.style("cursor", "not-allowed");
      this.cornerPointY.style("cursor", "not-allowed");
      this.mousePositionText.style("cursor", "not-allowed");
    }

    const centerPoint = document.getElementById(
      `centerPointId_${this.instanceId}`
    );
    if (!centerPoint) {
      console.error("circle dom element not found!");
      return;
    }

    const cornerPointX = document.getElementById(
      `cornerPointXId_${this.instanceId}`
    );
    if (!cornerPointX) {
      console.error("circle dom element not found!");
      return;
    }

    const cornerPointY = document.getElementById(
      `cornerPointYId_${this.instanceId}`
    );
    if (!cornerPointX) {
      console.error("circle dom element not found!");
      return;
    }

    const mousePositionText = document.getElementById(
      `mousePositionText_${this.instanceId}`
    );

    centerPoint.addEventListener("mousedown", this.getMouseDownListener(1));
    cornerPointX.addEventListener("mousedown", this.getMouseDownListener(2));
    cornerPointY.addEventListener("mousedown", this.getMouseDownListener(3));
    mousePositionText.addEventListener("mousedown", this.getMouseDownListener(4));

    const selfRef = this;
    centerPoint.ondblclick = (mouseDoubleClickEvent) => {
      mouseDoubleClickEvent.preventDefault();
      const obj = {
        selfRef,
        mouseX: mouseDoubleClickEvent.pageX,
        mouseY: mouseDoubleClickEvent.pageY,
      };
      openToolbar(obj);
    };
  }

  getMouseDownListener = (param) => (mouseDownEvent) => {
    mouseDownEvent.preventDefault();
    const ticksY = this.quadrant.formulars.newY.ticks();
    const domainY = this.quadrant.formulars.newY.domain();
    // defines the spapping distance
    const cutOff = domainY[1] / ticksY.length;

    // needed values to calculate formular dependent y values
    const { minDS, stepRate, formulaY, newX, newY } = this.quadrant.formulars;

    if (this.isCombined) {
      showCombinedLineDisabledToast();
    }

    const onMouseMove = (mouseMoveEvent) => {
      const cursorLocation = {
        x: this.valueXAxis,
        y: this.valueYAxis,
      };

      let baseArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

      // calculate y values for current cursor.y position
      const FormularValuesForCursorLocation = baseArray.map((line) => {
        const zValue = stepRate * line + minDS;
        return formulaY(cursorLocation.x, zValue);
      });

      // finde closes match
      var closestY = FormularValuesForCursorLocation.reduce(function (
        prev,
        curr
      ) {
        return Math.abs(curr - cursorLocation.y) <
          Math.abs(prev - cursorLocation.y)
          ? curr
          : prev;
      });

      // get difference between current Y and estimated Y
      const deltaY = Math.abs(cursorLocation.y - closestY);

      const newLocaltion =
        deltaY < cutOff ? { x: cursorLocation.x, y: closestY } : null;

      mouseMoveEvent.preventDefault();
      if (this.isCombined) { return; }
      if (this.allowedRange(mouseMoveEvent)) {
        const svgP = this.getSvgPosition(mouseMoveEvent);
        if (param === 1) {
          // cursorPosition transformed in [Pixels]
          const newXPixels =
            this.isLineSnapping && newLocaltion
              ? newX(newLocaltion?.x)
              : this.offsetX;
          const newYPixels =
            this.isLineSnapping && newLocaltion
              ? newY(newLocaltion?.y)
              : this.offsetY;

          this.updateOffsetByEvent(mouseMoveEvent);
          this.updatePosition(this.offsetX, this.offsetY);
          this.setAxisValues();
          this.moveCenterCircle(newXPixels, newYPixels);
          this.updatePositionCornerButtons(newXPixels, newYPixels);
          this.updateFixedValues(this.offsetX, this.offsetY);
          this.updateHorizontalLine(
            this.horizontalLine,
            newXPixels,
            newYPixels
          );
          this.updateVerticalLine(this.verticalLine, newXPixels, newYPixels);
          this.updateHorizontalLineText(
            this.horizontalText,
            newXPixels,
            newYPixels
          );
          if (this.stickTheLabel) {
            this.updateMousePositionText(this.offsetX, this.offsetY);
          } else {
            this.updateMousePositionText(this.labelX, this.labelY);
          }
          if (this.isSnapping) notifyOtherWorkingLines(this);
          storeWorkingLinesInLocalStorage(this.quadrant);
        } else if (param === 2) {
          this.updateOnlyOffsetX(mouseMoveEvent);
          this.updatePosition(this.offsetX, this.offsetY);
          this.setAxisValues();
          this.moveCenterCircle();
          this.updatePositionCornerButtons(this.offsetX, this.offsetY);
          this.updateHorizontalLine(
            this.horizontalLine,
            svgP.x,
            this.fixedYValue
          );
          this.updateVerticalLine(this.verticalLine, svgP.x, this.fixedYValue);
          this.updateHorizontalLineText(
            this.horizontalText,
            svgP.x,
            this.fixedYValue
          );
          if (this.stickTheLabel) {
            this.updateMousePositionText(this.offsetX, this.offsetY);
          } else {
            this.updateMousePositionText(this.labelX, this.labelY);
          }

          this.updateFixedValues(this.offsetX, this.offsetY);
          if (this.isSnapping) notifyOtherWorkingLines(this);
        } else if (param === 3) {
          this.updateOnlyOffsetY(mouseMoveEvent);
          this.updatePosition(this.offsetX, this.offsetY);
          this.setAxisValues();
          this.updatePositionCornerButtons(this.offsetX, this.offsetY);
          this.moveCenterCircle();
          this.updateHorizontalLine(
            this.horizontalLine,
            this.fixedXValue,
            svgP.y
          );
          this.updateVerticalLine(this.verticalLine, this.fixedXValue, svgP.y);
          this.updateHorizontalLineText(
            this.horizontalText,
            this.fixedXValue,
            svgP.y
          );
          if (this.stickTheLabel) {
            this.updateMousePositionText(this.offsetX, this.offsetY);
          } else {
            this.updateMousePositionText(this.labelX, this.labelY);
          }
          this.updateFixedValues(this.offsetX, this.offsetY);
          if (this.isSnapping) notifyOtherWorkingLines(this);
        } else if (param === 4 && this.stickTheLabel === false) {
          // cursorPosition transformed in [Pixels]
          const newXPixels =
            this.isLineSnapping && newLocaltion
              ? newX(newLocaltion?.x)
              : this.offsetX;
          const newYPixels =
            this.isLineSnapping && newLocaltion
              ? newY(newLocaltion?.y)
              : this.offsetY;

          this.updateOffsetByEvent(mouseMoveEvent);
          this.updateMousePositionText(this.offsetX, this.offsetY);
          storeWorkingLinesInLocalStorage(this.quadrant);
        }
        storeWorkingLinesInLocalStorage(this.quadrant);
      }
    };

    this.svgRef.current.addEventListener("mousemove", onMouseMove);

    this.svgRef.current.addEventListener("click", (event) => {
      this.svgRef.current.removeEventListener("mousemove", onMouseMove);
    });

    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.svgRef.current.removeEventListener("mousemove", onMouseMove);
      }
    });
  };

  setPosition() {
    if (this.valueXAxis !== -1 && this.valueYAxis !== -1) {
      const { pixelValueX, pixelValueY } = this.invertAxisValueToPixelValue(
        this.valueXAxis,
        this.valueYAxis
      );
      this.posX = pixelValueX;
      this.posY = pixelValueY;
      this.offsetX = pixelValueX;
      this.offsetY = pixelValueY;
    } else if (!this.noOffset) {
      const { offsetX, offsetY } = this.calculateOffsetFromPixelValue(
        this.posX,
        this.posY
      );
      this.offsetX = offsetX;
      this.offsetY = offsetY;
    }
  }

  updateFixedValues(offsetX, offsetY) {
    this.fixedXValue = offsetX;
    this.fixedYValue = offsetY;
  }

  updateWorkingLine(offsetX, offsetY) {
    if (offsetX !== null) this.offsetX = offsetX;
    if (offsetY !== null) this.offsetY = offsetY;
    this.updatePosition(this.offsetX, this.offsetY);
    this.updateFixedValues(this.offsetX, this.offsetY);
    this.setAxisValues();
    this.updateArrowMarker();
    this.moveCenterCircle();
    this.updatePositionCornerButtons(this.offsetX, this.offsetY);
    this.updateHorizontalLine(this.horizontalLine, this.offsetX, this.offsetY);
    this.updateVerticalLine(this.verticalLine, this.offsetX, this.offsetY);
    this.updateHorizontalLineText(
      this.horizontalText,
      this.offsetX,
      this.offsetY
    );
    if (this.stickTheLabel) {
      this.updateMousePositionText(this.offsetX, this.offsetY);
    } else {
      this.updateMousePositionText(this.labelX, this.labelY);
    }
    storeWorkingLinesInLocalStorage(this.quadrant);
  }

  updateWorkingLineByZoom(offsetX, offsetY) {
    this.updateOffset(offsetX, offsetY);
    this.centerPoint.attr("transform", `translate(${offsetX},${offsetY})`);
    this.updatePosition(this.offsetX, this.offsetY);
    this.updatePositionCornerButtons(this.offsetX, this.offsetY);
    this.updateHorizontalLine(this.horizontalLine, this.offsetX, this.offsetY);
    this.updateVerticalLine(this.verticalLine, this.offsetX, this.offsetY);
    this.updateHorizontalLineText(
      this.horizontalText,
      this.offsetX,
      this.offsetY
    );
    if (this.stickTheLabel === false) {
      this.updateMousePositionText(this.labelX, this.labelY);
    } else {
      this.updateMousePositionText(this.offsetX, this.offsetY);
    }
    storeWorkingLinesInLocalStorage(this.quadrant);
  }

  updateWorkingLineByValues(xValue, yValue) {
    const { pixelValueX, pixelValueY } = this.invertAxisValueToPixelValue(
      xValue,
      yValue
    );
    this.updateWorkingLine(pixelValueX, pixelValueY);
  }

  calculateOffsetFromPixelValue(posX, posY) {
    let offsetX = 0;
    let offsetY = 0;
    if (this.isFirstQuadrant) {
      offsetX = posX;
      offsetY = this.height - posY;
    }
    if (this.isSecondQuadrant) {
      offsetX = this.width - posX;
      offsetY = this.height - posY;
    }
    if (this.isThirdQuadrant) {
      offsetX = this.width - posX;
      offsetY = posY;
    }
    if (this.isFourthQuadrant) {
      offsetX = posX;
      offsetY = posY;
    }
    return { offsetX, offsetY };
  }

  calculatePixelValueFromOffset(offsetX, offsetY) {
    let posX = 0;
    let posY = 0;
    if (this.isFirstQuadrant) {
      posX = offsetX;
      posY = this.height - offsetY;
    }
    if (this.isSecondQuadrant) {
      posX = this.width - offsetX;
      posY = this.height - offsetY;
    }
    if (this.isThirdQuadrant) {
      posX = this.width - offsetX;
      posY = this.offsetY;
    }
    if (this.isFourthQuadrant) {
      posX = this.offsetX;
      posY = this.offsetY;
    }
    return { posX, posY };
  }

  svgPoint = (element, x, y) => {
    const pt = element.createSVGPoint();
    pt.x = x;
    pt.y = y;
    return pt.matrixTransform(element.getScreenCTM().inverse());
  };

  getSvgPosition = (event) =>
    this.svgPoint(
      this.svgRef.current,
      event.pageX - this.svgMargin.left,
      event.pageY - this.svgMargin.top
    );

  updateOffset = (offsetX, offsetY) => {
    this.offsetX = offsetX;
    this.offsetY = offsetY;
  };

  updatePosition = (offsetX, offsetY) => {
    const { posX, posY } = this.calculatePixelValueFromOffset(offsetX, offsetY);
    this.posX = posX;
    this.posY = posY;
  };

  updateOffsetByEvent = (event) => {
    const svgP = this.getSvgPosition(event);
    this.offsetX = svgP.x;
    this.offsetY = svgP.y;
  };

  updateOnlyOffsetX = (event) => {
    const svgP = this.getSvgPosition(event);
    this.offsetX = svgP.x;
  };

  updateOnlyOffsetY = (event) => {
    const svgP = this.getSvgPosition(event);
    this.offsetY = svgP.y;
  };

  drawHorizontalLine = (x, y, color) => {
    if (this.isFirstQuadrant || this.isFourthQuadrant) {
      return this.quadrant.plot
        .append("line")
        .attr("id", `horizontalLine_${this.instanceId}`)
        .style("stroke", color)
        .style("stroke-width", this.strokeWidth)
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("opacity", this.isVisible)
        .attr("x1", 0)
        .attr("y1", y)
        .attr("x2", x)
        .attr("y2", y)
        .attr("fill", "none")
        .attr(
          this.currentArrorMarkerHorizontal,
          this.isHorizontalArrayVisible
            ? `url(#arrow_${this.instanceId})`
            : null
        );
    }

    return this.quadrant.plot
      .append("line")
      .attr("id", `horizontalLine_${this.instanceId}`)
      .style("stroke", color)
      .style("stroke-width", this.strokeWidth)
      .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
      .style("opacity", this.isVisible)
      .attr("x1", x)
      .attr("y1", y)
      .attr("x2", this.width)
      .attr("y2", y)
      .attr("fill", "none")
      .attr(
        this.currentArrorMarkerHorizontal,
        this.isHorizontalArrayVisible ? `url(#arrow_${this.instanceId})` : null
      );
  };

  drawTextToHorizontalLine = (x, y, color) => {
    const nextNumber = this.sequenceNumber + 1;
    if (this.isFirstQuadrant || this.isFourthQuadrant) {
      return this.quadrant.plot
        .append("text")
        .attr("id", `horizontalText_${this.instanceId}`)
        .attr("y", y - 10)
        .attr("x", x / 2)
        .attr("text-anchor", "middle")
        .attr("class", "myLabel")
        .attr("fill", color)
        .attr("stroke", color)
        .style("opacity", this.isVisible)
        .text(nextNumber);
    }

    const length = x + (this.width - x) / 2;
    return this.quadrant.plot
      .append("text")
      .attr("id", `horizontalText_${this.instanceId}`)
      .attr("y", y - 10)
      .attr("x", length)
      .attr("text-anchor", "middle")
      .attr("class", "myLabel")
      .attr("fill", color)
      .attr("stroke", color)
      .style("opacity", this.isVisible)
      .text(nextNumber);
  };

  drawMousePositionText = (x, y, color) => {
    const roundedX = roundDecimalPlaces(this.valueXAxis, this.decimalPlaces);
    const roundedY = roundDecimalPlaces(this.valueYAxis, this.decimalPlaces);
    const roundedZ = roundDecimalPlaces(this.valueZ, this.decimalPlaces);
    if (this.isFirstQuadrant || this.isSecondQuadrant) {
      return this.quadrant.plot
        .append("text")
        .attr("id", `mousePositionText_${this.instanceId}`)
        .attr("x", x)
        .attr("y", y - 15)
        .attr("text-anchor", "middle")
        .attr("class", "myLabel")
        .attr("fill", color)
        .attr("stroke", color)
        .style("opacity", this.isVisible)
        .style("font-size", this.mousePositionTextSize)
        .text(`(${roundedX} / ${roundedY} / ${roundedZ})`);
    }
    return this.quadrant.plot
      .append("text")
      .attr("id", `mousePositionText_${this.instanceId}`)
      .attr("x", x)
      .attr("y", y + 25)
      .attr("text-anchor", "middle")
      .attr("class", "myLabel")
      .attr("fill", color)
      .attr("stroke", color)
      .style("opacity", this.isVisible)
      .style("font-size", this.mousePositionTextSize)
      .text(`(${roundedX} / ${roundedY} / ${roundedZ})`);
  };

  addArrowMarker = () => {
    this.arrowMarkerUUID = nextUUID();
    return this.quadrant.plot
      .append("defs")
      .append("marker")
      .attr("id", `arrow_${this.instanceId}${this.arrowMarkerUUID}`)
      .attr("viewBox", [0, -1, 20, 20])
      .attr("refX", 10)
      .attr("refY", 5)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 z")
      .style("fill", this.arrowDirectionIndex !== 5 ? this.color : "none")
      .style("stroke", this.arrowDirectionIndex !== 5 ? this.color : "none")
      .style("fill-opacity", 1);
  };

  removeArrowMarker = () => {
    if (this.arrowMarker) this.arrowMarker.remove();
  };

  drawVerticalLine = (x, y, color) => {
    if (this.isFirstQuadrant || this.isSecondQuadrant) {
      return this.quadrant.plot
        .append("line")
        .attr("id", `verticalLine_${this.instanceId}`)
        .style("stroke", color)
        .style("stroke-width", this.strokeWidth)
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("opacity", this.isVisible)
        .attr("x1", x)
        .attr("y1", this.height - 10)
        .attr("x2", x)
        .attr("y2", y)
        .attr(
          this.currentArrorMarkerVertical,
          this.isVerticalArrayVisible
            ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
            : null
        );
    }

    return this.quadrant.plot
      .append("line")
      .attr("id", `verticalLine_${this.instanceId}`)
      .style("stroke", color)
      .style("stroke-width", this.strokeWidth)
      .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
      .style("opacity", this.isVisible)
      .attr("x1", x)
      .attr("y1", 10)
      .attr("x2", x)
      .attr("y2", y)
      .attr(
        this.currentArrorMarkerVertical,
        this.isVerticalArrayVisible
          ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
          : null
      );
  };

  updateHorizontalLine = (workingLineHorizontal, valueX, valueY) => {
    if (this.isFirstQuadrant || this.isFourthQuadrant) {
      workingLineHorizontal
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("stroke", this.color)
        .style("stroke-width", this.strokeWidth)
        .style("opacity", this.isVisible)
        .attr("x1", 10)
        .attr("y1", valueY)
        .attr("x2", valueX)
        .attr("y2", valueY)
        .attr(
          this.currentArrorMarkerHorizontal,
          this.isHorizontalArrayVisible
            ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
            : null
        );
    }
    if (this.isSecondQuadrant || this.isThirdQuadrant) {
      workingLineHorizontal
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("stroke", this.color)
        .style("stroke-width", this.strokeWidth)
        .style("opacity", this.isVisible)
        .attr("x1", valueX)
        .attr("y1", valueY)
        .attr("x2", this.width - 10)
        .attr("y2", valueY)
        .attr(
          this.currentArrorMarkerHorizontal,
          this.isHorizontalArrayVisible
            ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
            : null
        );
    }
  };

  updateVerticalLine = (workingLineVertical, valueX, valueY) => {
    if (this.isFirstQuadrant || this.isSecondQuadrant) {
      workingLineVertical
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("stroke", this.color)
        .style("stroke-width", this.strokeWidth)
        .style("opacity", this.isVisible)
        .attr("x1", valueX)
        .attr("x2", valueX)
        .attr("y2", valueY)
        .attr(
          this.currentArrorMarkerVertical,
          this.isVerticalArrayVisible
            ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
            : null
        );
    }
    if (this.isThirdQuadrant || this.isFourthQuadrant) {
      workingLineVertical
        .style("stroke-dasharray", this.strokeDashArray[this.strokeIndex])
        .style("stroke", this.color)
        .style("stroke-width", this.strokeWidth)
        .style("opacity", this.isVisible)
        .attr("x1", valueX)
        .attr("x2", valueX)
        .attr("y2", valueY)
        .attr(
          this.currentArrorMarkerVertical,
          this.isVerticalArrayVisible
            ? `url(#arrow_${this.instanceId}${this.arrowMarkerUUID})`
            : null
        );
    }
  };

  updateHorizontalLineText = (svgText, valueX, valueY) => {
    if (this.isFirstQuadrant || this.isFourthQuadrant) {
      svgText
        .style("stroke", this.showLabels ? this.color : "transparent")
        .style("opacity", this.isVisible)
        .attr("x", valueX / 2)
        .attr("y", valueY - 10);
    }
    if (this.isSecondQuadrant || this.isThirdQuadrant) {
      const length = valueX + (this.width - valueX) / 2;
      svgText
        .style("stroke", this.showLabels ? this.color : "transparent")
        .style("opacity", this.isVisible)
        .attr("x", length)
        .attr("y", valueY - 10);
    }
  };

  moveCenterCircle = (x, y) => {
    const newX = x || this.offsetX;
    const newY = y || this.offsetY;

    if (this.centerPoint)
      this.centerPoint
        .style("opacity", this.isVisible)
        .attr("transform", `translate(${newX},${newY})`);
  };

  invertPixelValueToAxisValue(valueX, valueY) {
    const valueXAxis = this.x.invert(valueX);
    const valueYAxis = this.y.invert(valueY);
    return { valueXAxis, valueYAxis };
  }

  invertAxisValueToPixelValue(valueX, valueY) {
    const pixelValueX = this.x(valueX);
    const pixelValueY = this.y(valueY);
    return { pixelValueX, pixelValueY };
  }

  setAxisValues = () => {
    const { valueXAxis, valueYAxis } = this.invertPixelValueToAxisValue(
      this.offsetX,
      this.offsetY
    );
    this.valueXAxis = roundDecimalPlaces(valueXAxis, this.decimalPlaces);
    this.valueYAxis = roundDecimalPlaces(valueYAxis, this.decimalPlaces);
    const valueZ = this.formulaZ(this.valueXAxis, this.valueYAxis);
    this.valueZ = roundDecimalPlaces(valueZ, this.decimalPlaces);
  };

  allowedRange = (event) => {
    const svgP = this.getSvgPosition(event);
    const { valueXAxis, valueYAxis } = this.invertPixelValueToAxisValue(
      svgP.x,
      svgP.y
    );
    return (
      valueXAxis >= this.quadrant.xMin &&
      valueXAxis <= this.quadrant.xMax &&
      valueYAxis >= this.quadrant.yMin &&
      valueYAxis <= this.quadrant.yMax
    );
  };

  updateMousePositionText(x, y) {
    const roundedX = roundDecimalPlaces(this.valueXAxis, this.decimalPlaces);
    const roundedY = roundDecimalPlaces(this.valueYAxis, this.decimalPlaces);
    const roundedZ = roundDecimalPlaces(this.valueZ, this.decimalPlaces);

    let { positionY, positionX } = this.calculateMousePositionText(x, y);
    if (this.stickTheLabel === false) {
      positionX = x;
      positionY = y;
    }

    this.labelX = positionX;
    this.labelY = positionY;

    this.mousePositionText
      .style("stroke", this.showLabels ? this.color : "transparent")
      .attr("x", positionX)
      .attr("y", positionY)
      .style("opacity", this.isVisible).text(`(${formatNumber(
        roundedX,
        this.decimalPlaces
      )}
        / ${formatNumber(roundedY, this.decimalPlaces)}
        / ${formatNumber(roundedZ, this.decimalPlaces)})`);
  }

  calculateMousePositionText(posX, posY) {
    let positionX = posX;
    let positionY = posY;
    if (this.isFirstQuadrant || this.isSecondQuadrant) {
      if (positionY * 1.1 < 50) {
        positionY += 20;
      } else {
        positionY -= 15;
      }
    } else if (positionY * 1.1 > this.quadrant.height) {
      positionY -= 20;
    } else positionY += 25;

    if (positionX * 1.1 > this.quadrant.width) {
      positionX *= 0.9;
    }

    if (positionX * 1.1 < 50) {
      positionX += 75;
    }
    return { positionY, positionX };
  }

  setName(name) {
    this.name = name;
  }

  setStroke(index) {
    this.strokeIndex = index;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  setStrokeWidth(width) {
    this.strokeWidth = width;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  setDecimalPlaces(number) {
    this.decimalPlaces = number;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  setColor(color) {
    this.color = color;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  setArrowDirection(value) {
    let isLeft;
    let isDown;
    switch (value) {
      case 1: {
        const direction = ArrowDirection.diverge();
        isLeft = direction.isLeft;
        isDown = direction.isDown;
        this.updateArrowMarker();
        break;
      }
      case 2: {
        let direction;
        if (this.isSecondQuadrant || this.isFourthQuadrant) {
          direction = ArrowDirection.left();
        } else {
          direction = ArrowDirection.right();
        }
        isLeft = direction.isLeft;
        isDown = direction.isDown;
        this.updateArrowMarker();
        break;
      }
      case 3: {
        let direction;
        if (this.isSecondQuadrant || this.isFourthQuadrant) {
          direction = ArrowDirection.right();
        } else {
          direction = ArrowDirection.left();
        }
        isLeft = direction.isLeft;
        isDown = direction.isDown;
        this.updateArrowMarker();
        break;
      }
      case 4: {
        const direction = ArrowDirection.converge();
        isLeft = direction.isLeft;
        isDown = direction.isDown;
        this.updateArrowMarker();
        break;
      }
      default:
        this.removeArrowMarker();
    }

    this.arrowDirectionIndex = value;
    this.currentArrorMarkerHorizontal = isLeft
      ? this.arrorMarkerLeft
      : this.arrorMarkerRight;
    this.currentArrorMarkerVertical = isDown
      ? this.arrorMarkerDown
      : this.arrorMarkerUp;
    this.isHorizontalArrayVisible = true;
    this.isVerticalArrayVisible = true;

    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  hideLabel(showLabel) {
    this.showLabels = showLabel;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  stickLabel(stickLabel) {
    this.stickTheLabel = stickLabel;
    this.updateWorkingLine(this.offsetX, this.offsetY);
  }

  updateArrowMarker() {
    this.removeArrowMarker();
    this.arrowMarker = this.addArrowMarker();
  }

  getHorizontalWorkingLine() {
    if (!this.quadrant.horizontalNeighbor?.workingLines) return null;
    const array = this.quadrant.horizontalNeighbor.workingLines;
    const index = array.findIndex(
      (line) => line.sequenceNumber === this.sequenceNumber
    );
    if (index === -1) return null;
    return this.quadrant.horizontalNeighbor.workingLines[index];
  }

  getVerticalWorkingLine() {
    if (!this.quadrant.verticalNeighbor?.workingLines) return null;
    const array = this.quadrant.verticalNeighbor.workingLines;
    const index = array.findIndex(
      (line) => line.sequenceNumber === this.sequenceNumber
    );
    if (index === -1) return null;
    return this.quadrant.verticalNeighbor.workingLines[index];
  }

  getDiagonalWorkingLine() {
    if (!this.quadrant.diagonalNeighbor?.workingLines) return null;
    const array = this.quadrant.diagonalNeighbor.workingLines;
    const index = array.findIndex(
      (line) => line.sequenceNumber === this.sequenceNumber
    );
    if (index === -1) return null;
    return this.quadrant.diagonalNeighbor.workingLines[index];
  }

  initName(name) {
    if (name !== undefined) {
      this.name = name;
      return;
    }

    const lang = localStorage.getItem("i18nextLng")
      ? localStorage.getItem("i18nextLng")
      : "en";

    const horizontalWorkingLine = this.getHorizontalWorkingLine();
    const verticalWorkingLine = this.getVerticalWorkingLine();
    const diagonalWorkingLine = this.getDiagonalWorkingLine();
    if (horizontalWorkingLine) {
      this.name = horizontalWorkingLine.name;
      return;
    }
    if (verticalWorkingLine) {
      this.name = verticalWorkingLine.name;
      return;
    }
    if (diagonalWorkingLine) {
      this.name = diagonalWorkingLine.name;
      return;
    }

    if (lang.startsWith("de"))
      this.name = `Arbeitslinie ${this.sequenceNumber + 1}`;
    else this.name = `Workingline ${this.sequenceNumber + 1}`;
  }

  static increaseCounter = (index) => {
    if (index === 0) WorkingLine.counterQ1 += 1;
    if (index === 1) WorkingLine.counterQ2 += 1;
    if (index === 2) WorkingLine.counterQ3 += 1;
    if (index === 3) WorkingLine.counterQ4 += 1;
  };

  static decreaseCounter = (index) => {
    if (index === 0) WorkingLine.counterQ1 -= 1;
    if (index === 1) WorkingLine.counterQ2 -= 1;
    if (index === 2) WorkingLine.counterQ3 -= 1;
    if (index === 3) WorkingLine.counterQ4 -= 1;
  };

  static deleteWorkingLine(workingLine) {
    workingLine.quadrant.removeWorkingLine(workingLine);
    this.decreaseCounter(workingLine.index);

    workingLine.horizontalLine.remove();
    workingLine.verticalLine.remove();
    workingLine.horizontalText.remove();
    workingLine.mousePositionText.remove();
    workingLine.centerPoint.remove();
    workingLine.cornerPointX.remove();
    workingLine.cornerPointY.remove();

    storeWorkingLinesInLocalStorage(workingLine.quadrant);
  }

  static createStrokes() {
    return ["none", "5,5", "10,10", "20,10,5,5,5,10"];
  }

  static nextSequenceNumber(quadrant) {
    const sequenceNumbers = quadrant.workingLines.map(
      (line) => line.sequenceNumber
    );
    return findLowestNumberNotInList(0, sequenceNumbers);
  }

  static checkRange(valueXAxis, valueYAxis, quadrant) {
    return (
      valueXAxis >= quadrant.xMin &&
      valueXAxis <= quadrant.xMax &&
      valueYAxis >= quadrant.yMin &&
      valueYAxis <= quadrant.yMax
    );
  }
}
